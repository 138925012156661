import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

type Props = {
  category01: any,
  category02?: any,
  category03?: any
}

const CategoryBox:React.FC<Props> = ({ category01, category02, category03 }) =>{

  let categorys =[
    <Business>ビジネス</Business>,
    <Director>ディレクター</Director>,
    <Frontend>フロントエンド</Frontend>,
    <Designer>デザイナー</Designer>,
    <Marketing>マーケティング</Marketing>,
    <Verification>検証</Verification>,
    <Analysis>分析</Analysis>,
    <Backend>バックエンド</Backend>,
    <Other>その他</Other>
  ]

  let category = [];

  if( category01 === "ビジネス"){
    category[0] = categorys[0];
  }else if( category01 === "ディレクター"){
    category[0] = categorys[1];
  }else if( category01 === "フロントエンド"){
    category[0] = categorys[2];
  }else if( category01 === "デザイナー"){
    category[0] = categorys[3];
  }else if( category01 === "マーケティング"){
    category[0] = categorys[4];
  }else if( category01 === "検証"){
    category[0] = categorys[5];
  }else if( category01 === "分析"){
    category[0] = categorys[6];
  }else if( category01 === "バックエンド"){
    category[0] = categorys[7];
  }else if( category01 === "その他"){
    category[0] = categorys[8];
  }

  if( category02 === "ビジネス"){
    category[1] = categorys[0];
  }else if( category02 === "ディレクター"){
    category[1] = categorys[1];
  }else if( category02 === "フロントエンド"){
    category[1] = categorys[2];
  }else if( category02 === "デザイナー"){
    category[1] = categorys[3];
  }else if( category02 === "マーケティング"){
    category[1] = categorys[4];
  }else if( category02 === "検証"){
    category[1] = categorys[5];
  }else if( category02 === "分析"){
    category[1] = categorys[6];
  }else if( category02 === "バックエンド"){
    category[1] = categorys[7];
  }else if( category02 === "その他"){
    category[1] = categorys[8];
  }else{
    category[1] = "";
  }

  if( category03 === "ビジネス"){
    category[2] = categorys[0];
  }else if( category03 === "ディレクター"){
    category[2] = categorys[1];
  }else if( category03 === "フロントエンド"){
    category[2] = categorys[2];
  }else if( category03 === "デザイナー"){
    category[2] = categorys[3];
  }else if( category03 === "マーケティング"){
    category[2] = categorys[4];
  }else if( category03 === "検証"){
    category[2] = categorys[5];
  }else if( category03 === "分析"){
    category[2] = categorys[6];
  }else if( category03 === "バックエンド"){
    category[2] = categorys[7];
  }else if( category03 === "その他"){
    category[2] = categorys[8];
  }else{
    category[2] = "";
  }

  return(
    <LabelBox>
      { category[0] }
      { category[1] }
      { category[2] }
    </LabelBox >
  );
}

const LabelBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Label = styled.div`
  font-size: 1.4rem;
  line-height: 1.8em;
  display: block;
  width: 70px;
  text-align: center;
  border-radius: 3px;
  margin: 0 5px;
  ${media.lessThan("medium")`
    font-size: 1.2rem;
    margin: 5px;
  `}
`
const Business = styled(Label)`
  color: #1d8ad7;
  background-color: #cff4fe;
`
const Director = styled(Label)`
  width: 100px;
  color: #da635e;
  background-color: #fee3cf;
`
const Frontend = styled(Label)`
  width: 110px;
  color: #60b737;
  background-color: #ddfecf;
`
const Designer = styled(Label)`
  width: 90px;
  color: #ddb427;
  background-color: #fffe87;
`
const Marketing = styled(Label)`
  width: 115px;
  color: #e26fa0;
  background-color: #fecfe3;
`
const Verification = styled(Label)`
  width: 55px;
  color: #008277;
  background-color: #97e8e1;
`
const Analysis = styled(Label)`
  width: 55px;
  color: #ffffff;
  background-color: #5d7cc9;
`
const Backend = styled(Label)`
  width: 100px;
  color: #d95760;
  background-color: #fecfcf;
`
const Other = styled(Label)`
  width: 60px;
  color: #89898a;
  background-color: #e1e1e1;
`

export default CategoryBox;