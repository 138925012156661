import { QuestionType } from '../../Types/Types'

/*=========================
Lesson List
=========================*/
export const BusinessData: QuestionType[] = [
  {
    id:"0",
    title:"BtoB　B2B",
    ct01:"ビジネス",
    ct02:"マーケティング",
    ct03:"",
    level:3,
    exactly:"企業間取引を意味し、企業が企業に向けて製品やサービスを提供するビジネスモデルのこと",
    mistake01:"企業が一般消費者に対して製品・サービスを提供するビジネスモデルのこと",
    mistake02:"一般消費者が一般消費者にモノを売るビジネスモデルのこと",
    ex_mean:`BtoB(ﾋﾞｼﾞﾈｽ・ﾄｩ・ﾋﾞｼﾞﾈｽ)とは企業間取引を意味し、企業が企業に向けて製品やサービスを提供する取引のことを指します。<br><br>
    <h4 class="ttlMis">BtoBの例</h4>
    Web・アプリ制作会社<br>
    人材派遣<br>
    `,
    ex_relation:"	BtoC, CtoC"
  },
  {
    id:"1",
    title:"BtoC　B2C",
    ct01:"ビジネス",
    ct02:"マーケティング",
    ct03:"",
    level:3,
    exactly:"企業が一般消費者に対して製品・サービスを提供するビジネスモデルのこと",
    mistake01:"企業間取引を意味し、企業が企業に向けて製品やサービスを提供するビジネスモデルのこと",
    mistake02:"一般消費者が一般消費者にモノを売るビジネスモデルのこと",
    ex_mean:`BtoC(ﾋﾞｼﾞﾈｽ・ﾄｩ・ｶｽﾀﾏｰ)とは、企業が一般消費者に対して製品・サービスを提供する取引を指します。<br><br>
    <h4 class="ttlMis">BtoCの例</h4>
    小売店<br>
    ファミリーレストラン<br>
    東京ディズニーランド`,
    ex_relation:"BtoB, CtoC"
  },
  {
    id:"2",
    title:"CtoC　C2C",
    ct01:"ビジネス",
    ct02:"マーケティング",
    ct03:"",
    level:3,
    exactly:"一般消費者が一般消費者にモノを売るビジネスモデルのこと",
    mistake01:"企業が一般消費者に対して製品・サービスを提供するビジネスモデルのこと",
    mistake02:"企業間取引を意味し、企業が企業に向けて製品やサービスを提供するビジネスモデルのこと",
    ex_mean:`CtoC(ｶｽﾀﾏｰ・ﾄｩ・ｶｽﾀﾏｰ)とは、一般消費者が一般消費者にモノを売るビジネスモデルを指します。<br><br>
    <h4 class="ttlMis">CtoCの例</h4>
    フリーマーケット<br>
    YouTuber`,
    ex_relation:"BtoB, BtoC"
  },
  {
    id:"3",
    title:"KPI",
    ct01:"ビジネス",
    ct02:"",
    ct03:"",
    level:3,
    exactly:"最終的な目標を達成するための過程を計測する「中間目標・指標」のこと",
    mistake01:"「最終的な目標」が達成されているかを計測するための指標のこと",
    mistake02:"目標を達成するために必要な「業務環境」指標のこと",
    ex_mean:`KPI(ｷｰ・ﾊﾟﾌｫｰﾏﾝｽ・ｲﾝｼﾞｹｰﾀｰ)とは最終的な目標を達成するための過程を計測する「中間目標・指標」のことです。<br>
    日本語では「重要業績評価指標」と呼ばれます。<br><br>
    <h4 class="ttlMis">KPIの設定に必要なSMART要素</h4>
    KPIの設定には「SMART」と呼ばれる、それぞれの頭文字からなす以下の5つの概念を基に設定する必要があります。<br>
    ・Specific(明確性)<br>
    ・Measurable(計量性)<br>
    ・Achievable(現実性)<br>
    ・Result oriented or Relevant(結果指向または関連性)<br>
    ・Time bound(適時性)<br><br>
    <h4 class="ttlMis">KPI設定の例</h4>
    KGI(最終目標)：HTML5認定試験の合格<br>
    KPI(中間目標)：1日2時間、週で14時間の勉強時間を確保する<br>
    1週間ごとにその週で学んだ内容をブログへアウトプットする<br>
    1ヵ月で参考書を1冊読破する。`,
    ex_relation:"KGI, KSF"
  },
  {
    id:"4",
    title:"KGI",
    ct01:"ビジネス",
    ct02:"",
    ct03:"",
    level:3,
    exactly:"「最終的な目標」が達成されているかを計測するための指標のこと",
    mistake01:"最終的な目標を達成するための過程を計測する「中間目標・指標」のこと",
    mistake02:"目標を達成するために必要な「業務環境」指標のこと",
    ex_mean:`KGI(ｷｰ・ｺﾞｰﾙ・ｲﾝｼﾞｹｰﾀｰ)とは、最終目標が達成されているかを計測するための指標のことを指します。<br>
    KGIは曖昧な指標では意味を成さないため、誰でも公平に判断できるよう時期と具体的な数値を設定し、明確な判断基準としておくことが基本となります。<br>
    例えば営業などで「月の売り上げを上げる」などはKGIとは呼べず「月の売り上げを～%上げるために、客単価を～から～に増やす」などがKGIと呼べるでしょう。`,
    ex_relation:"KGI, KSF"
  },
  {
    id:"5",
    title:"PDCAサイクル",
    ct01:"ビジネス",
    ct02:"",
    ct03:"",
    level:3,
    exactly:"「継続的」な業務の効率化・最適化を計る手法のこと",
    mistake01:"やらなければいけないこと、忘れてはいけないことなどを一覧化したリストのこと",
    mistake02:"「短期的」な業務の効率化・最適化を計る手法のこと",
    ex_mean:`PDCAサイクルとは<br>
    ・Plan (計画)<br>
    ・Do (実行)<br>
    ・Check (評価)<br>
    ・Action (改善)<br>
    の頭文字をとったもので、上記のフローを継続的に回すことで、業務の効率化・最適化を計る手法の1つです。`,
    ex_relation:"Todo, チームビルディング"
  },
  {
    id:"6",
    title:"ペルソナ",
    ct01:"ビジネス",
    ct02:"マーケティング",
    ct03:"",
    level:4,
    exactly:"製品・サービスを購入、または利用する仮想的な「ユーザーモデル」のこと",
    mistake01:"製品・サービスの販売促進において、購入または利用するユーザーの行動、思考、感情を分析し、認知から検討、購入・利用へ至る「シナリオ」を設定すること",
    mistake02:"ビジネスとして利益を継続的に生み出す製品・サービス、あるいは「収益の仕組み」のこと",
    ex_mean:`ペルソナとは製品・サービスを利用する人物を想定した、仮想的なユーザーモデルのことです。<br>
    実際にその人物が実在しているかのように、年齢、性別、職業、年収、価値観、家族構成……などのリアリティのある詳細な情報を設定することで、
    ユーザー視点での製品・サービスの完成度を高めるために用いられます。<br>
    またペルソナを活用する際は、ユーザーの行動、思考、感情を分析し、認知から検討、購入・利用へ至る「シナリオ」を設定した
    「カスタマージャーニー」をいうマーケティング手法と一緒に用いられることが多いです。<br><br>
    <h4 class="ttlMis">ペルソナの具体例</h4>
    「食べログ」のようなサービスを開発する場合、以下のようなレベルでペルソナ設定します。<br>
    ターゲット：20代〜30代、女性、主婦、料理好き<br>
    氏名：中村 久子<br>
    年齢：28歳<br>
    職業：看護師<br>
    年収：450万<br>
    住居：都内<br>
    家族構成：夫、長女(5)、長男(3)の４人家族<br>
    日課：基本的な情報収集はスマーフォンを使用し、特にInstagramで料理系のアカウントを日頃チェックしている。また友人とのやりとりはLINEを使用することが多い`,
    ex_relation:"カスタマージャーニー"
  },
  {
    id:"7",
    title:"サブスクリプションモデル",
    ct01:"ビジネス",
    ct02:"マーケティング",
    ct03:"",
    level:5,
    exactly:"製品やサービスごとにお金を支払うのではなく、それを一定期間利用できる「権利」に対してお金を支払うビジネスモデルのこと",
    mistake01:"製品やサービスごとにお金を支払い、その物自体の「永続的な所有権」に対してお金を支払うビジネスモデルのこと",
    mistake02:"基本的な製品やサービスは無料で提供し、その後「有料版」やユーザーが必要とする一部コンテンツに対してお金を支払うビジネスモデルのこと",
    ex_mean:`消費者が製品やサービスごとにお金を支払うのではなく、それを一定期間利用できる「権利」に対してお金を支払うビジネスモデルです。<br>
    またサブスクリプションモデルへの誘導として、まず無料で使ってもらい製品・サービスの魅力を知ってもらった上で、有料版へと移行してもらう「フリーミアムモデル」
    というモデルを挟むケースが多いです。<br><br>
    <h4 class="ttlMis">サブスクリプションモデルを採用している例</h4>
    Apple Music<br>
    Netflix<br>
    Amazonプライム・ビデオ<br>
    Adobe CC
    `,
    ex_relation:"ライセンスモデル, フリーミアムモデル"
  },
  {
    id:"8",
    title:"フリーミアムモデル",
    ct01:"ビジネス",
    ct02:"マーケティング",
    ct03:"",
    level:5,
    exactly:"基本的な製品やサービスは無料で提供し、その後「有料版」やユーザーが必要とする一部コンテンツに対してお金を支払うビジネスモデルのこと",
    mistake01:"製品やサービスごとにお金を支払い、その物自体の「永続的な所有権」に対してお金を支払うビジネスモデルのこと",
    mistake02:"製品やサービスごとにお金を支払うのではなく、それを一定期間利用できる「権利」に対してお金を支払うビジネスモデルのこと",
    ex_mean:`基本的なサービスや製品は無料で提供し、その後「有料版」やユーザーが必要とする一部コンテンツに対してお金を支払うビジネスモデルのことです。<br>
    よくある誤解として「1ヶ月分無料」などの、有料契約の一部割引などはフリーミアムモデルとは呼びません<br><br>
    <h4 class="ttlMis">フリーミアムモデルを採用している例</h4>
    パズル&ドラゴンズ<br>
    Slack<br>
    DropBox`,
    ex_relation:"サブスクリプションモデル, ライセンスモデル"
  },
  {
    id:"9",
    title:"オーガニック検索",
    ct01:"マーケティング",
    ct02:"",
    ct03:"",
    level:2,
    exactly:"Yahoo!やGooleなどの検索エンジンからキーワードを入力してページを検索すること",
    mistake01:"検索したいキーワードに関連したバナーなどの広告をクリックすること",
    mistake02:"QRコードなどの別媒体から目的のページを検索すること",
    ex_mean:`Yahoo!やGooleなどの検索エンジンからキーワードを入力してページを検索することです。<br>
    または検索してきたユーザーをオーガニックユーザーと言います。`,
    ex_relation:"SEO, リスティング広告"
  },
  {
    id:"10",
    title:"リスティング広告",
    ct01:"マーケティング",
    ct02:"",
    ct03:"",
    level:3,
    exactly:"YahooやGoogleのような検索エンジンを利用した際に、キーワードに基づいて検索結果の上部に表示される広告のこと",
    mistake01:"Webページのサイドバーなどに表示される広告のこと",
    mistake02:"YouTubeなどの動画配信サービスで、動画を閲覧する際に流れる広告のこと",
    ex_mean:`YahooやGoogleのような検索エンジンを利用した際に、キーワードに基づいて検索結果の上部に表示される広告のことで、PPC(ﾍﾟｲ・ﾊﾟｰ・ｸﾘｯｸ)広告と呼ばれる「クリック報酬型」の広告です。<br>
    検索結果の上位2、3ほどに表示され、ページタイトル下のURLに「広告」と記載してあるのが特徴です。<br>
    これを提供する有名なサービスとして「Yahoo!JAPANプロモーション広告」や「Google AdWords」などがあげられます。`,
    ex_relation:"オーガニック検索, マス広告"
  },
  {
    id:"11",
    title:"リスクヘッジ",
    ct01:"ビジネス",
    ct02:"",
    ct03:"",
    level:2,
    exactly:"想定されるリスクの程度を予測して、リスクに対応できる体制を備えておくこと",
    mistake01:"想定されるリスクの中で、起こりえない、或いは限りなく確立の低いリスクをリストアップすること",
    mistake02:"問題が発生した際に、その対応を上位者や管理者に任せること",
    ex_mean:`想定されるリスクの程度を予測して、リスクに対応できる体制を備えておくことです、単にヘッジと呼ぶこともあります。`,
    ex_relation:"リスクマネジメント"
  },
  {
    id:"12",
    title:"ナレッジ",
    ct01:"ビジネス",
    ct02:"",
    ct03:"",
    level:3,
    exactly:"企業やそれに所属する個人が保有している、有益な知識・経験・事例・ノウハウなど付加価値のある情報のこと",
    mistake01:"想定されるリスクの程度を予測して、リスクに対応できる体制を備えておくこと",
    mistake02:"所属している企業に対する、従業員一人一人の「生活満足度」の指標のこと",
    ex_mean:`企業やそれに属する個人が保有している、有益な知識・経験・事例・ノウハウなど付加価値のある情報のことです。<br>
    仕事の効率化を図るために、仕事で上手くいったやり方、失敗したやり方をチームメイト各自でまとめて話し合う「ナレッジコミュニケーション」や、
    蓄積した経験や知識、情報を全社的に共有し、企業が持つ競争力を活性・向上させる経営手法の「ナレッジマネジメント」などあらゆる場面で利用されます。`,
    ex_relation:"特になし"
  },
  {
    id:"13",
    title:"リテラシー",
    ct01:"ビジネス",
    ct02:"",
    ct03:"",
    level:2,
    exactly:"専門分野における基本的な知識や理解、または分析、活用する能力のこと",
    mistake01:"専門分野において、過去に正しいとされていたが現代では誤った知識や理解のこと",
    mistake02:"異なる専門分野においても共通して適用できる汎用的な知識や能力のこと",
    ex_mean:`専門分野における基本的な知識や理解、または分析、活用する能力のこと指す言葉で、IT業界に対する知識力を「ITリテラシー」と言ったり、
    ビジネスにおける振る舞いや言葉遣いなどを「ビジネスリテラシー」と言ったりします。`,
    ex_relation:"特になし"
  },
  {
    id:"14",
    title:"エビデンス",
    ct01:"ビジネス",
    ct02:"フロントエンド",
    ct03:"",
    level:3,
    exactly:"「証拠」や「根拠」を意味し、ビジネスにおける取引実績、契約書や承認、ITにおけるシステムのテスト結果や各種ログのこと",
    mistake01:"「信頼」や「誠実」を意味し、ビジネスにおける取引実績、製品やコネクション、ITにおける実績のあるオープンソースやプラットフォームのこと",
    mistake02:"「発展」や「変革」を意味し、ビジネスにおける組織改革や収益モデル、ITにおける技術革新のこと",
    ex_mean:`「証拠」や「根拠」を意味し、ビジネスシーンをはじめ、幅広い分野において用いられている言葉。<br>
    ビジネスにおいては「取引実績」「契約書」や「承認」などを指し、ITにおいてはテスト結果や各種ログなどの実証データをのこと指します。<br>
    顧客先にシステムの仕様書などを送り、内容の了承を得た場合、この「了承を得た」と言う事実や、やり取りのメール内容を含め「エビデンスを取った」などと言います。`,
    ex_relation:"特になし"
  },
  {
    id:"15",
    title:"アサイン",
    ct01:"ビジネス",
    ct02:"",
    ct03:"",
    level:3,
    exactly:"仕事を割り当てたり、任務・部署・地位などを任命すること",
    mistake01:"契約を履行する前に承認を得る、仮契約のこと",
    mistake02:"複数の責任者の承認が必要な契約のこと",
    ex_mean:`仕事を割り当てたり、任務・部署・地位などを任命することです。<br><br>
    <h4 class="ttlMis">アサインを使用する例</h4>
    開発スケジュールに間に合わせるため、近々エンジニアを10名ほどアサインさせる<br>
    入社後、運営チームにアサインされた`,
    ex_relation:"特になし"
  },
  {
    id:"16",
    title:"リマインド",
    ct01:"ビジネス",
    ct02:"ディレクター",
    ct03:"",
    level:3,
    exactly:"仕事の締め切りやイベントの予定などを「再確認、再告知」すること",
    mistake01:"仕事の締め切りやイベントの予定などの「スケジュールを立て直す」こと",
    mistake02:"仕事の締め切りやイベントの予定などが「スケジュール通りに進行している」状態のこと",
    ex_mean:`仕事の締め切りやイベントの予定などを「再確認、再告知」することです。<br><br>
    <h4 class="ttlMis">リマインドを活用する例</h4>
    経費精算書などの月末提出が必要なものを数日前にリマインドする<br>
    待ち合わせの際、時間通りに間に合うか否かをリマインドする`,
    ex_relation:"特になし"
  },
  {
    id:"17",
    title:"バッファ",
    ct01:"ビジネス",
    ct02:"ディレクター",
    ct03:"",
    level:3,
    exactly:"スケジュールや締め切り、製品などにおける予備の余力時間や製品の在庫のこと",
    mistake01:"業務において、特定の人物のみが所有している権限こと",
    mistake02:"ユーザーの製品やサービスにおける感想や意見のこと",
    ex_mean:`スケジュールや締め切り、製品などにおける予備の余力時間や製品の在庫のことです。<br>
    もともとIT業界におけるコンピューター用語の「保有領域」と言う意味でしたが、ビジネスでは「余力」「猶予」という意味で使用することが多いです。<br>
    ディレクターなどはエンジニアに仕事を割り当てる際、通常の締め切りに対し、敢えて多めにスケジュールと取ることがあり、この余力が「バッファ」分
    と言うことになります。`,
    ex_relation:"特になし"
  },
  {
    id:"18",
    title:"ボトムアップ",
    ct01:"ビジネス",
    ct02:"ディレクター",
    ct03:"",
    level:3,
    exactly:"企業経営やチーム運用において組織の下層部の意見を収集し、それを元に上層部が規則や今後の方針を決めていく管理方式のこと",
    mistake01:"作業工程や生産工程において全体の効率や品質をおとしめている原因を割り出すこと",
    mistake02:"企業経営やチーム運用において組織の上層部が規則や今後の方針を決め、下層部はそれに基づいて行動していく管理方式のこと",
    ex_mean:`企業経営やチーム運用において組織の下層部の意見を収集し、それを元に上層部が規則や今後の方針を決めていく管理方式のことです。<br>
    会社全体や部署毎ではなく、チーム内のような小規模でこのボトムアップの管理方式を採用することが多いです。`,
    ex_relation:"特になし"
  },
  {
    id:"19",
    title:"トップダウン",
    ct01:"ビジネス",
    ct02:"ディレクター",
    ct03:"",
    level:2,
    exactly:"企業経営やチーム運用において組織の上層部が規則や今後の方針を決め、下層部はそれに基づいて行動していく管理方式のこと",
    mistake01:"作業工程や生産工程において全体の効率や品質をおとしめている原因を割り出すこと",
    mistake02:"企業経営やチーム運用において組織の下層部の意見を収集し、それを元に上層部が規則や今後の方針を決めていく管理方式のこと",
    ex_mean:`企業経営やチーム運用において組織の上層部が規則や今後の方針を決め、下層部はそれに基づいて行動していく管理方式のことです。<br>
    会社全体や部署毎など、大きな規模でこのトップダウン管理方式を採用することが多いです。`,
    ex_relation:"特になし"
  },
  {
    id:"20",
    title:"アジェンダ",
    ct01:"ビジネス",
    ct02:"",
    ct03:"",
    level:2,
    exactly:"会議や日程の議題、各項目のこと",
    mistake01:"新しい商品やサービスを世に送り出すこと",
    mistake02:"ユーザーの製品やサービスにおける感想や意見のこと",
    ex_mean:`会議や日程の議題、各項目のことです。<br>
    会議など行われる際に、資料やプロジェクターに表示されていることが多いです。`,
    ex_relation:"レジェメ"
  },
  {
    id:"21",
    title:"インバウンドマーケティング",
    ct01:"ビジネス",
    ct02:"マーケティング",
    ct03:"",
    level:5,
    exactly:"SNSやブログや動画配信など「ユーザーが自発的に行動・検索」することによって、製品・サービスに興味・関心を持ってもらえるよう仕掛けるマーケティング手法のこと",
    mistake01:"広告やイベントやTVCMなど「企業が主体」となってユーザーに製品・サービスに興味・関心を持ってもらえるよう仕掛けるマーケティング手法のこと",
    mistake02:`SNSをはじめとした各種メディアにおいて「強い影響力をもつ人物」に企業の製品やサービスを紹介してもらい、製品・サービスに興味・関心を持ってもらえるよう仕掛けるマーケティング手法のこと`,
    ex_mean:`SNSやブログや動画配信など「ユーザーが自発的に行動・検索」することによって、製品・サービスに興味・関心を持ってもらえるよう仕掛けるマーケティング手法のことです。<br>
    以前は、広告やイベントやTVCMなど「企業が主体」となってユーザーに売り込む「アウトバウンドマーケティング」が主流でしたが、スマーフォンの普及により、ユーザーがいつでも情報にアクセス出来るようになったことや、SNS市場の拡大などが影響して現在では「インバウンドマーケティング」も主流になっているそうです。<br><br>
    <h4 class="ttlMis">インバウンドマーケティングの例</h4>
    マクドナルドが新作を発売する際に公式Twitterを使い告知する<br>
    アパレルの店員がInstagramに自社製品を着用したコーデ画像を載せる`,
    ex_relation:"アウトバウンドマーケティング, インフルエンサーマーケティング"
  },
  {
    id:"22",
    title:"アウトバウンドマーケティング",
    ct01:"ビジネス",
    ct02:"マーケティング",
    ct03:"",
    level:4,
    exactly:"広告やイベントやTVCMなど「企業が主体」となってユーザーに製品・サービスに興味・関心を持ってもらえるよう仕掛けるマーケティング手法のこと",
    mistake01:"SNSやブログや動画配信など「ユーザーが自発的に行動・検索」することによって、製品・サービスに興味・関心を持ってもらえるよう仕掛けるマーケティング手法のこと",
    mistake02:"SNSをはじめとした各種メディアにおいて「強い影響力をもつ人物」に企業の製品やサービスを紹介してもらい、製品・サービスに興味・関心を持ってもらえるよう仕掛けるマーケティング手法のこと",
    ex_mean:`広告やイベントやTVCMなど「企業が主体」となってユーザーに製品・サービスに興味・関心を持ってもらえるよう仕掛けるマーケティング手法のことです。<br>
    また昨今ではスマーフォンの普及やSNS市場の拡大により、SNSやブログや動画配信など「ユーザーが自発的に行動・検索」することによって製品・サービスを売り込む「インバウンドマーケティング」も
    主流となってきています。<br><br>
    <h4 class="ttlMis">アウトバウンドマーケティングの例</h4>
    東京ゲームショウなどのイベント<br>
    ポスティングチラシ<br>
    TVCM`,
    ex_relation:"インフルエンサーマーケティング, インバウンドマーケティング"
  },
  {
    id:"23",
    title:"インフルエンサーマーケティング",
    ct01:"ビジネス",
    ct02:"マーケティング",
    ct03:"",
    level:4,
    exactly:"SNSをはじめとした各種メディアにおいて「強い影響力をもつ人物」に企業の製品やサービスを紹介してもらい、製品・サービスに興味・関心を持ってもらえるよう仕掛けるマーケティング手法のこと",
    mistake01:"広告やイベントやTVCMなど「企業が主体」となってユーザーに製品・サービスに興味・関心を持ってもらえるよう仕掛けるマーケティング手法のこと",
    mistake02:"SNSやブログや動画配信など「ユーザーが自発的に行動・検索」することによって、製品・サービスに興味・関心を持ってもらえるよう仕掛けるマーケティング手法のこと",
    ex_mean:`SNSをはじめとした各種メディアにおいて「強い影響力をもつ人物」に企業の製品やサービスを紹介してもらい、製品・サービスに興味・関心を持ってもらえるよう仕掛けるマーケティング手法のことで、
    それらの影響力を持つ人物を「インフルエンサー」と呼びます。<br><br>
    <h4 class="ttlMis">注意点</h4>
    重要なポイントとして「企業から依頼された案件であること」をインフルエンサー自身が告知する必要があり、これを行わないと「ステルスマーケティング(ステマ)」と言う、非営利の好評価を装い消費者を
    欺く行為、として非難を浴びることがあります。<br><br>
    <h4 class="ttlMis">インフルエンサーマーケティングの例</h4>
   有名なYoutuberの動画で製品をレビューしてもらう<br>
   芸能人のブログやSNSの製品のレビューや写真を投稿してもらう`,
    ex_relation:"インバウンドマーケティング, アウトバウンドマーケティング, ステルスマーケティング"
  },
  {
    id:"24",
    title:"カスタマージャーニー",
    ct01:"ビジネス",
    ct02:"マーケティング",
    ct03:"",
    level:4,
    exactly:"製品・サービスの販売促進において、購入または利用するユーザーの行動、思考、感情を分析し、認知から検討、購入・利用へ至る「シナリオ」を設定すること利用するユーザーの行動、思考、感情を分析し、認知から検討、購入・利用へ至る「シナリオ」を設定すること",
    mistake01:"製品・サービスを購入、または利用する仮想的な「ユーザーモデル」を設定すること",
    mistake02:"ビジネスとして利益を継続的に生み出す製品・サービス、あるいは「収益の仕組み」のこと",
    ex_mean:`製品・サービスの販売促進において、購入または利用する人物の行動、思考、感情を分析し、認知から検討、購入・利用へ至る「シナリオ」を設定することです。<br>
    またシナリオを設定する際には「ペルソナ」という仮想のユーザーモデルを設定し、シナリオを構築していきます。<br><br>
    <h4 class="ttlMis">旅行プラン会社のカスタマージャーニーの例</h4>
    【ペルソナ】<br>
    友人と2人で旅行を行く計画を立てている20代の都内に住む日本人女性、基本的な情報取集はスマートフォンから<br><br>

    【行動1：宿泊候補を探すためにWebサイトを閲覧する】<br>
    -思考- 写真通りの部屋なのかな?、料金はいつ払うの?、SNSに載せれるような撮影スポットはあるのかな?<br><br>
    【行動2：宿泊先をフォームにて予約】<br>
    -思考- ちゃんと届いたかな?、予約人数間違えてないかな?<br><br>
    【行動3：宿泊地に赴く】<br>
    -思考- 地図から目的地までの所要時間はどれくらいか?、美味しい食事や、面白いスポットを写真SNSに共有したい！<br><br>
    【行動4：帰宅】<br>
    -思考- 充実した旅だった<br><br>
    ※ これはあくまで一部を紹介したものであり、実際には上記のものより克明にカスタマージャーニーを設定して行きます
    `,
    ex_relation:"ペルソナ"
  },
  {
    id:"25",
    title:"アウトソーシング",
    ct01:"ビジネス",
    ct02:"",
    ct03:"",
    level:3,
    exactly:"社外から生産に必要な製品や人材調達したり、業務の一部を一括して他社に任せたりする経営手法のこと",
    mistake01:"他社へ委託していた業務を見直し、自社で業務行うことで費用の削減を図る経営手法のこと",
    mistake02:"企業が「在宅業務」「時短業務」のような働き方を整備することで、雇用の多様化や費用の削減を図る経営手法のこと",
    ex_mean:`社外から生産に必要な製品や人材調達したり、業務の一部を一括して他社に任せたりする経営手法のことです。<br>
    また、他社へ委託していた業務を見直し、自社で業務行うことで費用の削減を図る経営手法のこと「インソーシング」と言います。`,
    ex_relation:"特になし"
  },
  {
    id:"26",
    title:"ブレスト",
    ct01:"ビジネス",
    ct02:"ディレクター",
    ct03:"マーケティング",
    level:2,
    exactly:"テーマに対して参加者が自由にディスカッションを行い、アイデアを出していくことで新しい考え方や解決策を出していく手法のこと",
    mistake01:"・客観的 ・消極的 ・積極的 ・革新的 ・感情的 ・分析的 という6種類の視点を持った参加者がそれぞれの役回りを演じて議論を行い、アイデアや解決策を出していく手法のこと",
    mistake02:"「こうすればもっと良くなる」という思いつきを可能な限り用紙に記入していき、その中から現実味のあるアイデアや解決策を出していく手法のこと",
    ex_mean:`正式には「ブレインストーミング」と言い、テーマに対して参加者が自由にディスカッションを行い、アイデアを出していくことで新しい考え方や解決策を出していく手法のことです。<br>
    また発言に対して批判や否定をしてはいけないというのが基本ルールがあり、反対意見があったらそれを述べるだけで、他の参加者が出した意見に対する批判であってはいけません。`,
    ex_relation:"シックスハット法"
  },
  {
    id:"27",
    title:"クロージング",
    ct01:"ビジネス",
    ct02:"ディレクター",
    ct03:"",
    level:3,
    exactly:"顧客と契約を締結すること、または契約成立に至るまでのプロセスやアプローチを含めた一連行動のこと",
    mistake01:"他社へ委託していた業務を見直し、自社で業務行うことで費用の削減を図る経営手法のこと",
    mistake02:"テーマに対して参加者が自由にディスカッションを行い、アイデアを出していくことで新しい考え方や解決策を出していく手法のこと",
    ex_mean:`「顧客と契約を締結すること」を意味していますが、実際には契約に至るまでのアプローチを含めた行動全体のことを表していることが多いです。<br>
    また、契約達成率のことを「クロージング力」と言うこともあるようです。<br><br>
    <h4 class="ttlMis">契約アプローチの一例</h4>
    結論を導き出しやすい環境を整えて顧客の不安や疑問を解消する<br>
    決断するきっかけを与え、顧客の背中を押してあげる`,
    ex_relation:"特になし"
  },
  {
    id:"28",
    title:"ブルー・オーシャン",
    ct01:"ビジネス",
    ct02:"マーケティング",
    ct03:"",
    level:1,
    exactly:"競争相手のいない未開拓のビジネスモデルや市場のこと",
    mistake01:"競争率の高いビジネスモデルや市場のこと",
    mistake02:"ビジネスや市場としての価値が極めて低いこと",
    ex_mean:`競争相手のいない未開拓のビジネスモデルや市場のことです。`,
    ex_relation:"レッド・オーシャン"
  },
  {
    id:"29",
    title:"BI",
    ct01:"ビジネス",
    ct02:"分析",
    ct03:"",
    level:4,
    exactly:"企業の情報システムなどに蓄積される膨大なデータを収集して分析し、その結果を「可視化」して業務や経営の意思決定に活用すること",
    mistake01:"「企業の社会的責任」を意味し、収益を求めるだけなく、環境活動、ボランティア、寄付活動など、企業としての社会貢献の活動のこと",
    mistake02:"「法令遵守」を意味し、企業ならびに各従業員が法律や企業が定めたルールなどを一般的な倫理観持って遵守し、経営や業務を行うこと",
    ex_mean:`BI(ﾋﾞｼﾞﾈｽ・ｲﾝﾃﾘｼﾞｪﾝｽ)とは、企業の情報システムなどに蓄積される膨大なデータを収集して分析し、
    その結果を「可視化」して業務や経営の意思決定に活用することです。<br>
    またそれらを扱うツールのことをBIツールと呼びます。<br><br>
    <h4 class="ttlMis">BI活用されているシーン</h4>
    <table class="table">
      <tr>
        <th>予算管理</th>
      </tr>
      <tr>
        <td>Excelデータなどに集計された、年度毎の予算データをグラフ化(可視化)し今期の予算決定に役立てる</td>
      </tr>
    </table>
    <table class="table">
      <tr>
        <th>残業分析</th>
      </tr>
      <tr>
        <td>各課毎の残業時間をグラフ化(可視化)して業務の改善に役立てる</td>
      </tr>
    </table>
    <table class="table">
      <tr>
        <th>顧客分析</th>
      </tr>
      <tr>
        <td>地域毎の売り上げメタデータから顧客層をビジュアライズ(可視化)し、今後のマーケティングに役立てる</td>
      </tr>
    </table>

    `,
    ex_relation:"DX, SI"
  },
  {
    id:"30",
    title:"ローンチ",
    ct01:"ビジネス",
    ct02:"ディレクター",
    ct03:"",
    level:3,
    exactly:"新しく製品やサービスを立ち上げ、市場や世の中に向けて発信すること",
    mistake01:"日々の業務を体系的に組織化した反復可能な業務活動のこと",
    mistake02:"スケジュールや締め切りなどにおける、予備の余力時間や製品の在庫のこと",
    ex_mean:`新しく製品やサービスを立ち上げ、市場や世の中に向けて発信することです。<br>
    元々はWebサイトやWebアプリケーションを新たに公開する場合など、IT業界で使われていた用語ですが、
    新商品や新サービスの公開という意味でも一般的に用いられているようです。`,
    ex_relation:"特になし"
  },
  {
    id:"31",
    title:"DX",
    ct01:"ビジネス",
    ct02:"フロントエンド",
    ct03:"",
    level:5,
    exactly:"デジタル技術を駆使して、経済活動やビジネスモデル、さらには組織・文化・制度といった企業そのものを「変革」していく一連の取り組みのこと",
    mistake01:"パソコン・ソフトウェア・データベース・ネットワークなどを連携させ一体化することで、新たなサービスを生み出したりするシステムやその環境を構築すること、またそれらを保守・管理していくこと",
    mistake02:"デジタル技術を駆使して、製品・サービスの付加価値を高めたり、業務の効率化を図る一連の取り組みのこと",
    ex_mean:`DX(ﾃﾞｼﾞﾀﾙ・ﾄﾗﾝｽﾌｫｰﾒｰｼｮﾝ)とはデジタル技術を駆使して、経済活動やビジネスモデル、さらには組織・文化・制度といった企業そのものを変革していく一連の取り組みのことです。<br>
    これらは、IoT、AI、SIなどのデジタル技術を総合的に用いて行われ、近年ではあらゆる企業がこのDXへの取り組みを始めています。<br><br>
    <h4 class="ttlMis">DXの成功事例</h4>
    DXの成功例としてタイムズの「カーシェア」が有名でしょう。<br>
    それまで「指定の時間・店舗に車を借りに行き、業務時間内に返却する」と言うレンタカーのビジネスモデルを、
    車にクレジットカードの決済機能を付け(IoT)、在庫状況などをアプリで管理し(SI)、車をタイムズの余っている駐車場に置いてくことで、
    24時間いつでもレンタカーを利用し、返却することが可能になり、それまでのビジネスモデルに大きな革命をもたらしました。<br>
    さらに店舗を持たないので、人件費含めた諸々の経費削減を実現して業績を伸ばしたそうです。`,
    ex_relation:"IoT, SI, AI, デジタライゼーション"
  },
  {
    id:"32",
    title:"SI",
    ct01:"ビジネス",
    ct02:"フロントエンド",
    ct03:"",
    level:3,
    exactly:"パソコン・ソフトウェア・データベース・ネットワークなどを連携させ一体化することで、新たなサービスを生み出すシステムやその環境を構築すること、またそれらを保守・管理していくこと",
    mistake01:"デジタル技術を駆使して、経済活動やビジネスモデル、さらには組織・文化・制度といった企業そのものを「変革」していく一連の取り組みのこと",
    mistake02:"デジタル技術を駆使して、製品・サービスの付加価値を高めたり、業務の効率化を図る一連の取り組みのこと",
    ex_mean:`SI(ｼｽﾃﾑ・ｲﾝﾃｸﾞﾚｰｼｮﾝ)とは、パソコン・ソフトウェア・データベース・ネットワークなどを連携させ一体化することで、
    新たなサービスを生み出したりするシステムやその環境を構築すること、またそれらを保守・管理していくことです。<br><br>
    <h4 class="ttlMis">SIの中に含まれる技術領域</h4>
    Web・アプリ制作<br>
    データベース構築・管理<br>
    社内のローカルインターネット環境構築<br>
    ソフトウェア開発<br>
    といやいわゆる「デジタル技術」に関わるものは全般的にSIの領域となります。<br><br>
    <h4 class="ttlMis">Sler(ｽﾗｰ)</h4>
    SI仕事に従事する人たちを「Sler(ｽﾗｰ)」と呼びますが、これは和製英語で日本のみで通用する言葉です。`,
    ex_relation:"IoT, DX, AI, デジタライゼーション"
  },
  {
    id:"33",
    title:"IoT",
    ct01:"ビジネス",
    ct02:"フロントエンド",
    ct03:"",
    level:4,
    exactly:"「モノのインターネット」を意味し、様々な「モノ」がインターネットに接続され、情報交換することにより相互的に関わりをもつ仕組みのこと",
    mistake01:"「クモのインターネット」を意味し、インターネットを経由してストレージにコンピューター資源を保存・利用する仕組みのこと",
    mistake02:"「ヒトのインターネット」を意味し、あらゆる「ヒト」同士が繋がって構成されたインターネット上のコミュニティのこと",
    ex_mean:`IoT(ｲﾝﾀｰﾈｯﾄ・ｵﾌﾞ・ｼﾝｸ)とは、「モノのインターネット」を意味し様々な「モノ」がインターネットに接続され、情報交換することにより相互に制御する仕組みのことです。<br><br>
    <h4 class="ttlMis">IoTの活用事例</h4>
    <table class="table">
      <tr>
        <th class="wid-20">繋がる物</th></th><th>機器</th>
      </tr>
      <tr>
        <td>時計 ＋ IT</td><td>Apple Watchなどのスマートウォッチ</td>
      </tr>
      <tr>
        <td>家電 ＋ IT</td><td>人を判別するAIを搭載したエアコンや生存確認を行う電気ポット</td>
      </tr>
      <tr>
        <td>車 ＋ IT</td><td>カーシェアのクレジット決済の機能を持った</td>
      </tr>
    </table>`,
    ex_relation:"DX, AI, SI"
  },
  {
    id:"34",
    title:"コンプライアンス",
    ct01:"ビジネス",
    ct02:"",
    ct03:"",
    level:5,
    exactly:"「法令遵守」を意味し、企業ならびに各従業員が法律や企業が定めたルールなどを一般的な倫理観持って遵守し、経営や業務を行うこと",
    mistake01:"「守秘義務契約」を意味し、企業が定めた、人の間で締結する営業秘密や個人情報など業務に関して知った秘密を第三者に開示しないとする「契約」のこと",
    mistake02:"「独占禁止」を意味し、健全で公正な競争状態を維持するために、独占的、協調的、あるいは競争方法として不公正な行動を防ぐことを目的とした法律、ルールのこと",
    ex_mean:`コンプライアンスとは「法令遵守」を意味し、企業ならびに各従業員が法律や企業が定めたルールなどを一般的な倫理観持って遵守し、経営や業務を行うことです。<br>
    また企業によっては業務に従事する際に「NDA 秘密保持契約」という企業秘密を口外しない、という契約書を書くこともあります。<br><br>
    <h4 class="ttlMis">コンプライアンス違反の例</h4>
    <table class="table">
      <tr>
        <th colspan="2" class="wid-20">法律的</th>
      </tr>
      <tr>
        <td class="wid-20">景品表示法</td>
        <td>製品・サービスの値段や効果などを嘘や大げさな表示によって消費者を欺むく行為</td>
      </tr>
      <tr>
        <td>個人情報保護法</td>
        <td>個人情報のセキュリティ環境の不備、消費者が合意していない情報を取り扱う行為</td>
      </tr>
      <tr>
        <td>食品衛生法</td>
        <td>保存方法や保存期間などを無視して食品を流通させる行為</td>
      </tr>
    </table>

    <table class="table">
      <tr>
        <th class="wid-20">個人的</th>
      </tr>
      <tr>
        <td>企業秘密の口外</td>
      </tr>
      <tr>
        <td>PCやセキュリティカードなどの支給品紛失</td>
      </tr>
      <tr>
        <td>企業情報を使い副次的な収入を得る</td>
      </tr>
      <tr>
        <td>従業員の個人情報の口外</td>
      </tr>
    </table>
    `,
    ex_relation:"特になし"
  },
  {
    id:"35",
    title:"なるはや ASAP",
    ct01:"ビジネス",
    ct02:"ディレクター",
    ct03:"",
    level:1,
    exactly:"業務や質問に対する返信などを可能な限り早く行うこと、またはそれを依頼すること",
    mistake01:"新しく製品やサービスを立ち上げ、市場や世の中に向けて発信すること",
    mistake02:"競争相手のいない未開拓のビジネスモデルや市場のこと",
    ex_mean:`業務や質問に対する返信などを可能な限り早く行うこと、またはそれを依頼することです。<br>
    制作の修正依頼などを受けるときに「なるはやでお願いします」や「メールの返信はASAP(ｱｽﾞ・ｽｰﾝ・ｱｽﾞ・ﾎﾟｯｼﾌﾞﾙ)で」といった使い方をします。`,
    ex_relation:""
  },
  {
    id:"36",
    title:"FIX",
    ct01:"ビジネス",
    ct02:"ディレクター",
    ct03:"",
    level:2,
    exactly:"プロジェクトや仕事で方針を確定した時やスケジュールが確定した時などの「確定」「決定」と言う意味のこと",
    mistake01:"ある行動や結果を人や組織に共有したり、それらの意見やアドバイスをもらうこと",
    mistake02:"業務や質問に対する返信などを可能な限り早く行うこと、またはそれを依頼すること",
    ex_mean:`プロジェクトや仕事で方針を確定した時やスケジュールが確定した時などの「確定」「決定」と言う意味のことです。<br>
    使用例としては「顧客へ提出したデザイン案がFixした」「この業務はまだ諸々Fixしていないから後回しで」といった具合で使用されます。`,
    ex_relation:"特になし"
  },
  {
    id:"37",
    title:"エスカレーション",
    ct01:"ビジネス",
    ct02:"",
    ct03:"",
    level:3,
    exactly:"上位者、上席者の指示を仰ぐこと。また顧客対応において自身だけで対応が困難な状況を上位の管理者やなどに交代して対応してもらうこと",
    mistake01:"周囲に及ぼす「悪影響」や他人に「強く影響を与える」こと",
    mistake02:"プロジェクトや仕事で方針を確定した時やスケジュールが確定した時などの「確定」「決定」と言う意味のこと",
    ex_mean:`上位者、上席者の指示を仰ぐこと。また顧客対応において自身だけで対応が困難な状況を上位の管理者やなどに交代して対応してもらうことです。<br><br>
    <h4 class="ttlMis">使用例</h4>
    トラブルが起きた際に「それは自分で判断しないでエスカレーションしたほういいよ」など周囲から忠言を受けたり。<br>
    「～の場合は直ぐにエスカレーションして」などと言った指示としても使用されます。`,
    ex_relation:"特になし"
  },
  {
    id:"38",
    title:"MTG",
    ct01:"ビジネス",
    ct02:"",
    ct03:"",
    level:1,
    exactly:"「会議」や「打ち合わせ」のこと",
    mistake01:"世界的に有名な「カードゲーム」のこと",
    mistake02:"業務や質問に対する返信などを可能な限り早く行うこと、またはそれを依頼すること",
    ex_mean:`MTG(ﾐｰﾃｨﾝｸﾞ)は「会議」や「打ち合わせ」のことを意味します。<br>
    エム・ティー・ジーと口で言う人はあまりいませんが、スケジュール表などにMTGと書かれていることが多いです。`,
    ex_relation:""
  },
  {
    id:"39",
    title:"オンスケ",
    ct01:"ビジネス",
    ct02:"ディレクター",
    ct03:"",
    level:1,
    exactly:"作業や計画がスケジュール通りに進んでいる状況のこと",
    mistake01:"「会議」や「打ち合わせ」のこと",
    mistake02:"仕事の締め切りやイベントの予定などの「スケジュールを立て直す」行為のこと",
    ex_mean:`オンスケ(ｵﾝｽｹｼﾞｭｰﾙ)とは、作業や計画がスケジュール通りに進んでいる状況のことを意味します。<br>
    作業報告の際に「オンスケで進んでいます」と答えたり、業務の進捗確認の際に「オンスケで進んでいますか？」と言った具合で使用されます。`,
    ex_relation:"リスケ"
  },
  {
    id:"40",
    title:"ソリューション",
    ct01:"ビジネス",
    ct02:"",
    ct03:"",
    level:3,
    exactly:"企業がかかえる課題に対して人員やノウハウ、ソフトウェアなどの様々なリソースで解決すること",
    mistake01:"企業の情報システムなどに蓄積される膨大な業務データを収集して分析し、その結果を可視化して業務や経営の意思決定に活用すること",
    mistake02:"市場や顧客の分析を行う際に、不特定多数の人々を「趣向」「地理的条件」「フィールド」「人口変異状況」などの同じニーズや性質を持つ固まりに区分すること",
    ex_mean:`「問題解決」を意味し、企業がかかえる課題に対して人員やノウハウ、ソフトウェアなどの様々なリソースで解決することを意味します。<br>
    また何かの分野に特化したソリューションを「○○ソリューション」とも言ったりします。<br><br>
    <h4 class="ttlMis">ソリューションの例</h4>
    ITソリューション、教育ソリューション`,
    ex_relation:"特になし"
  },
  {
    id:"41",
    title:"サマリー",
    ct01:"ビジネス",
    ct02:"分析",
    ct03:"",
    level:2,
    exactly:"「要約」を意味し、資料や会議のポイントをまとめた表や議事録、あるいは分析結果を一覧にした合計データのこと",
    mistake01:"市場や顧客の分析を行う際に、不特定多数の人々を「趣向」「地理的条件」「フィールド」「人口変異状況」などの同じニーズや性質を持つ固まりに区分すること",
    mistake02:"企業がかかえる課題に対して人員やノウハウ、ソフトウェアなどの様々なリソースで解決すること",
    ex_mean:`「要約」を意味し、資料や会議のポイントをまとめた表や議事録、あるいは分析結果をカテゴリー別に表示した合計データのことを意味します。<br>
    サマリー自体様々な分野で使用されている言葉で、その意味も業界によって若干の差異がありますが「要約」と言う意味から大きく外れることはありません。`,
    ex_relation:"BI, アナリティクス"
  },
  {
    id:"42",
    title:"リソース",
    ct01:"ビジネス",
    ct02:"ディレクター",
    ct03:"マーケティング",
    level:2,
    exactly:"「資源」を意味し、業務上で必要となる人材、情報、時間、予算…etc などあらゆる資産のこと",
    mistake01:"「発信」を意味し、新しく製品・サービスなどを世の中に送り出すこと",
    mistake02:"「再利用」を意味し、企業が過去の成功事例から、製品・サービスを展開する為に必要な知識・ノウハウなどの情報を流用すること",
    ex_mean:`「資源」を意味し、業務上で必要となる人材、情報、時間、予算…etc などあらゆる資産のことを意味しします。<br>
    業界、職種問わず使用される言葉です。`,
    ex_relation:"特になし"
  },
  {
    id:"43",
    title:"ハレーション",
    ct01:"ビジネス",
    ct02:"",
    ct03:"",
    level:2,
    exactly:"周囲に及ぼす「悪影響」や他人に「強く影響を与える」こと",
    mistake01:"周囲に及ぼす「良い影響」や他人に「それとなく与える影響」のこと",
    mistake02:"周囲に「影響されていない」こと",
    ex_mean:`周囲に及ぼす「悪影響」や他人に「強く影響を与える」ことを意味します。<br>
    語源としては「影響」ですが、基本的に「悪影響」の意味合いで使用されます。<br><br>
    <h4 class="ttlMis">使用例</h4>
    「ささいな間違いだと無視をしていたが、後々にチームに多大なハレーションが起きてしまった」<br>
    「ハレーションを生まないように、取引先への言動には気を付けるようにしよう」<br><br>
    <h4 class="ttlMis">デザイン業界での意味</h4>
    デザイン業界では上記の意味加えて「彩度の高い色同士の組み合わせで、目がチカチカする」と言う意味でも使用されます。`,
    ex_relation:"特になし"
  },
  {
    id:"44",
    title:"アフィリエイト広告",
    ct01:"ビジネス",
    ct02:"マーケティング",
    ct03:"",
    level:4,
    exactly:"ブロガーやインフルエンサーが運営するメディアに広告を掲載し、メディアを閲覧するユーザーがその広告をクリックしたり、リンク先のページにアクセスして何らかの条件を満たすことで成果報酬が発生する広告のこと",
    mistake01:"YahooやGoogleのような検索エンジンを利用した際に、キーワードに基づいて検索結果に上部に表示される広告のこと",
    mistake02:"テレビやラジオ、新聞や雑誌などに掲載される、不特定多数の視聴者、読者へ向けた広告のこと",
    ex_mean:`アフィリエイト広告とはブロガーやインフルエンサーが運営するメディアに広告を掲載し、メディアを閲覧するユーザーがその広告をクリックしたり、
    リンク先のページにアクセスして何らかの条件を満たすことで成果報酬が発生する広告のことです。<br>
    何を条件とするかは広告によって異なり「商品の購入」「資料請求」「会員登録」などが上げられます。<br><br>
    <h4 class="ttlMis">アフィリエイター</h4>
    アフィリエイト広告を掲載して収入を得る人たちを「アフィリエイター」と呼び、アフィリエイト広告はASP(ｱﾌｨﾘｴｲﾄ・ｻｰﾋﾞｽ・ﾌﾟﾛﾊﾞｲﾀﾞ)
    という広告主とアフィリエイターをマッチングするサービスを利用することで始められます。<br><br>
    <h4 class="ttlMis">有名なASP一覧</h4>
    ValueCommerce、A8.net、楽天アフィリエイト、Amazonアソシエイト
    `,
    ex_relation:"リスティング広告, マス広告, SEO, インフルエンサー"
  },
  {
    id:"45",
    title:"SEO",
    ct01:"ビジネス",
    ct02:"マーケティング",
    ct03:"ディレクター",
    level:5,
    exactly:"検索エンジンのオーガニックな検索結果において、特定のWebサイトが上位に表示されるようWebサイトの構成などを調整する行為、またその手法の総称のこと",
    mistake01:"SNSやブログや動画配信など「ユーザーが自発的に行動・検索」することによって、製品・サービスに興味・関心を持ってもらえるよう仕掛けるマーケティング手法のこと",
    mistake02:"YahooやGoogleのような検索エンジンを利用した際に、キーワードに基づいて検索結果に上部に表示される広告のこと",
    ex_mean:`SEO(ｻｰﾁ・ｴﾝｼﾞﾝ・ｵﾌﾟﾃｨﾏｲｽﾞ)とは「検索エンジン最適化」とも言い、検索エンジンのオーガニックな検索結果において、
    特定のWebサイトが上位に表示されるようWebサイトの構成などを調整する行為、またその手法の総称のことです。SEO対策とも言います。<br><br>
    <h4 class="ttlMis">SEOは対策は2種類</h4>
    SEOは対策は「内部SEO」「外部SEO」に種別され、内部SEOはWebサイトの内容やプログラム構成などを検索エンジンが理解しやすいように構築することで、
    外部SEOは被リンク(SNSや他のページにリンクを貼られたり、お気に入りに登録されたり)を多く獲得することです。<br>ただし作為的な被リンクの操作などはペナルティを課せられることがあります。<br>
    これらはあくまで有効な対策であると言われている行為であり、実際の検索エンジン上位に表示される条件は200以上からなるGoogleの検索アルゴリズムから判断されるそうです。
    `,
    ex_relation:"オーガニック検索, リスティング広告, クローラー"
  },
  /* {
    id:"",
    title:"",
    ct01:"",
    ct02:"",
    ct03:"",
    level:3,
    exactly:"",
    mistake01:"",
    mistake02:"",
    ex_mean:``,
    ex_scene:``,
    ex_relation:""
  }, */

];

