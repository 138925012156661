import React, { useRef } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

type Props = {
  forKey: string,
  exactly?: string,
  mistake01?: string,
  mistake02?: string,
  func1?: any,
  func2?: any,
}

const QuestionItem:React.FC<Props> = ({ exactly, mistake01, mistake02, forKey, func1, func2}) =>{

  return(
    <Question__ListItem>
      { forKey === "ex00" ?
      <div>
        <SelectInput name="question" id={forKey} type="checkbox" onClick={func2}/>
        <label htmlFor={forKey}>
          { exactly }
        </label>
      </div>
      :
      <div>
        <SelectInput name="question" id={forKey} type="checkbox" onClick={func1}/>
        <label htmlFor={forKey}>
          { mistake01 }
          { mistake02 }
        </label>
      </div>
      }
    </Question__ListItem>
  );
}

const Question__ListItem = styled.li`
  font-size: 1.6rem;
  line-height: 1.5em;
  color: #2b546a;
  cursor: pointer;
  text-indent: -1em;
  margin-bottom: 1.6em;
  a{
    color: #2b546a;
  }
  ${media.lessThan("medium")`
    font-size: 1.4rem;
    text-indent: -0.8em;
  `}
`

const SelectInput = styled.input`
  position: relative;
  top: 1px;
  right: 7px;
  display:inline-block !important;
  width: 8px;
  height: 8px;
  background-color: #61d3fb;
  border-radius: 100px;
  cursor:pointer;
  ${media.lessThan("medium")`
    top: 0px;
    width: 5px;
    height: 5px;
  `}
`

export default QuestionItem;