import React, { useState, useEffect } from 'react';
import Header01 from '../CommonParts/Header01';
import QuestionItem from './parts/QuestionItem';
import QuestionTitle from './parts/QuestionTitle';
import AnswerModal from './parts/Answer';
import MistakeModal from './parts/Mistake';
import TotalModal from './parts/Total';
import CategoryBox from './parts/CategoryBox';
import StarBox from './parts/Star';
import { FrontData }　from '../../Data/QuestionData/FrontData';
import styled from 'styled-components';
import media from 'styled-media-query';

//問題をシャッフル
let archiveNumber = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36];
let a = archiveNumber.length;
while (a) {
  var j = Math.floor( Math.random() * a );
  var t = archiveNumber[--a];
  archiveNumber[a] = archiveNumber[j];
  archiveNumber[j] = t;
};

let n = 0;
let current = archiveNumber[n];


const Question:React.FC = () => {
　　
  //正解総数
  const [ total, setTotal] = useState(0);

  //現在の問題
  const [ residue , setResidue] = useState(1);

  //モーダル判定
  const [ isMistake , setMistake] = useState(0);

  let mistakeOpen = () => {
    setMistake(1);
  }

  let AnswerOpen = () => {
    setTotal(total + 1);
    setMistake(2);
  }

  let nextQuestion = () => {
    setMistake(0);
    n += 1;
    current = archiveNumber[n];
    setResidue(residue + 1);
  }
  let endQuestion = () => {
    setMistake(3);
    n = 0;
    current = archiveNumber[0];
    setResidue(0);
  }

  /* current = 37; */

  //問題の位置をランダム表示
  const question = [
    <QuestionItem exactly={FrontData[current].exactly} forKey={"ex00"} func2={AnswerOpen}/>,
    <QuestionItem mistake01={FrontData[current].mistake01} forKey={"ex01"} func1={mistakeOpen}/>,
    <QuestionItem mistake01={FrontData[current].mistake02} forKey={"ex02"} func1={mistakeOpen}/>
  ]

  let b = question.length;
  while (b) {
    var k = Math.floor( Math.random() * b );
    var u = question[--b];
    question[b] = question[k];
    question[k] = u;
  };

  return(
    <Content>
      <Header01 />
      <div className="Inner">
        {isMistake === 1 ?
          <MistakeModal
          title={FrontData[current].title}
          text={FrontData[current].ex_mean}
          relation={FrontData[current].ex_relation}
          residue={residue}
          func1={nextQuestion}
          func2={endQuestion} />
          :""}
        {isMistake === 2 ?
          <AnswerModal
          funcA1={nextQuestion}
          funcA2={endQuestion}
          residue={residue}
          category={36}
          />
        :""}
        {isMistake === 3 ?
          <TotalModal total={total} />
        :""}

        <Cotainer>
          <QuestionBox>
            <Question__Inner>
              <Question__header>
                <CategoryBox
                  category01={FrontData[current].ct01}
                  category02={FrontData[current].ct02}
                  category03={FrontData[current].ct03}
                />
                <Residue>{ residue }/37</Residue>
              </Question__header>
              <StarBox levels={FrontData[current].level} />
              <QuestionTitle title={FrontData[current].title} />
                <Question__List>
                  { question[0] }
                  { question[1] }
                  { question[2] }
                </Question__List>
            </Question__Inner>
          </QuestionBox>
        </Cotainer>
      </div>
    </Content>
  )
}

const Residue = styled.div`
  font-size: 1.4rem;
  color: #2b546f;
`
const Question__header = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.lessThan("medium")`
    flex-wrap: wrap;
    flex-basis: 70%;
    margin-bottom: 10px;
  `}
`

const SelectInput = styled.input`
  position: relative;
  top: 2px;
  right: 6px;
  display:inline-block !important;
  padding: 4px;
  border: 1px solid #d0d0d0;
  border-radius: 100px;
  cursor:pointer;
  &:checked{
    background-color: #fb617e;
    border: 1px solid #fb617e;
  }
`

const AnswerButton = styled.div`
  background-color: #fb617e;
  color: #ffffff;
  width: 100%;
  max-width: 140px;
  text-align: center;
  border-radius: 3px;
  margin: 0 auto;
  button{
    color: #ffffff;
    font-size: 2.4rem;
    line-height: 40px;
    display: block;
    text-align: center;
    width: 100%;
  }
`

const Content = styled.div`
  background-color: #f9fbfe;
  height: 100%;
`
const Cotainer = styled.div`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  ${media.lessThan("medium")`
    padding: 0 15px;
  `}
`
const QuestionBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #ffffff;
  width: 900px;
  margin: 0 auto;
  padding: 30px 30px 0px 30px;
  box-shadow: 0px 0px 8px #eaeaea;
  ${media.lessThan("medium")`
    width: 100%;
    padding: 20px 15px 0px 15px;
  `}
`
const Question__Inner = styled.div`
  width: 100%;
  margin-bottom: 25px;
`
const Question__List = styled.ul`
  width: 75%;
  text-align: left;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${media.lessThan("medium")`
    width: 87%;
  `}
`

export default Question;