import React from 'react';
import LogoImg from './Logo';
import pt1 from '../../Asets/common/pt1.jpg';
import styled from 'styled-components';
import media from 'styled-media-query';


const Header01:React.FC = () => {
  return(
    <Header>
      <Logo>
        <LogoImg />
      </Logo>
    </Header>
  )
}

const Header = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height:130px;
  background-image: url('${pt1}');
  margin-bottom: 40px;
  ${media.lessThan("medium")`
    height: 50px;
    margin-bottom: 20px;
    background-size: 4px;
  `}
`

const Logo = styled.div`
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
  img{
    width:100%;
  }
  ${media.lessThan("medium")`
    max-width: 100px;
  `}
`

export default Header01;