import React from 'react';
import Circle from 'react-circle';
import styled from 'styled-components';
import media from 'styled-media-query';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';

type Props = {
  total: number,
}

const TotalModal:React.FC<Props> = ({ total }) => {

  let rate = Math.floor(total / 46 * 100);

  return(
    <Total>
      <Total__overlay>
        <Total__inner>
          <Total__title>結果発表</Total__title>

          <AnswerRate>- 正解数 -</AnswerRate>
          <AnswerTotal>{ total }</AnswerTotal>

          <CircleBox>
            <AnswerRate>- 正解率 -</AnswerRate>
              <Circle
                roundedStroke={true}
                animate={true}
                progress={rate}
                lineWidth="20"
                size="170"
                textColor="#2b546a"
                progressColor="#6ce0ff"
                percentSpacing={1}
                textStyle={{font: '7rem 游ゴシック体, Arial, sans-serif'}}
              />
          </CircleBox>
          <Comment>
            {rate <= 10 ? "あなたのビジネス細胞はミジンコです":""}
            {rate > 10 && rate <= 20 ? "あなたのビジネス細胞はカエルです":""}
            {rate > 20 && rate <= 30 ? "あなたのビジネス細胞はスズメです":""}
            {rate > 30 && rate <= 40 ? "あなたのビジネス細胞はネコです":""}
            {rate > 40 && rate <= 50 ? "あなたのビジネス細胞はペンギンです":""}
            {rate > 50 && rate <= 60 ? "あなたのビジネス細胞はゾウです":""}
            {rate > 60 && rate <= 70 ? "あなたのビジネス細胞はクジラです":""}
            {rate > 70 && rate <= 80 ? "あなたのビジネス細胞は恐竜です":""}
            {rate > 80 && rate <= 90 ? "あなたのビジネス細胞はゴジラです":""}
            {rate > 90 && rate <= 99 ? "あなたのビジネス細胞は宇宙です":""}
            {rate === 100 ? "パーフェクト！素晴らしい！":""}
          </Comment>
          <SlectBtn>
            <EndBtn><Link to="/Menu">おわり</Link></EndBtn>
          </SlectBtn>
        </Total__inner>
      </Total__overlay>
    </Total>
  )
}
const AnswerTotal = styled.p`
  font-size: 5rem;
  text-align: center;
  margin-bottom: 30px;
  ${media.lessThan("medium")`
    font-size: 3.5rem;
    margin-bottom: 15px;
  `}
`

const AnswerRate = styled.p`
  text-align: center;
  font-size: 2rem;
  margin-bottom: 10px;
`
const Comment = styled.p`
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 40px;
  line-height: 1.5em;
  ${media.lessThan("medium")`
    margin-bottom: 15px;
  `}
`

const CircleBox = styled.div`
  margin: 0 auto;
  padding-bottom: 10px;
`

const Total__title = styled.p`
  color: #2b546a;
  font-size: 2.6rem;
  padding-bottom: 10px;
  text-align: center;
  line-height: 1.4em;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 20px;
`

const Total = styled.div`
  position: fixed;;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
`
const Total__overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
`
const Total__inner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 450px;
  height: 570px;
  padding: 30px;
  background-color: #ffffff;
  color: #2b546a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;
  ${media.lessThan("medium")`
    width: 80%;
    height: 490px;
    padding: 10px;
  `}
`


const SlectBtn = styled.div`
  display: flex;
  justify-content: space-around;
  width: 60%;
  margin: 0 auto;
`

const EndBtn = styled.div`
  width: 140px;
  background-color: #646464;
  text-align: center;
  border-radius: 2px;
  a{
    display: block;
    width: 100%;
    color: #ffffff;
    font-size: 2.3rem;
    line-height: 1.9em;
  }
  ${media.lessThan("medium")`
  a{
    font-size: 1.8rem;
  }
  `}
`

export default TotalModal;