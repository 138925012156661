import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

type Props = {
  levels: any
}

const StarBox:React.FC<Props> = ({ levels }) =>{

  let tag = [];
  if( levels === 1){
    tag[0] = "★";
    tag[1] = "★★★★";
  }else if( levels === 2 ){
    tag[0] = "★★";
    tag[1] = "★★★";
  }else if( levels === 3 ){
    tag[0] = "★★★";
    tag[1] = "★★";
  }else if( levels === 4 ){
    tag[0] = "★★★★";
    tag[1] = "★";
  }else if( levels === 5 ){
    tag[0] = "★★★★★";
  }

  return(
    <div>
      <Star>
        <span>{ tag[0] }</span>{ tag[1] }
      </Star>
    </div>
  );
}

const Star = styled.p`
  font-size:1.8rem;
  color: #e1e1e1;
  text-align: center;
  margin-bottom: 18px;
  span{
    color: #fb617e;
  }
  ${media.lessThan("medium")`
    font-size:1.4rem;
    margin-bottom: 10px;
  `}
`

export default StarBox;