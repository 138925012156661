import React from 'react';
import styled from 'styled-components';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';

type Props = {
  key: number,
  title: string,
  img: string,
  lead: string,
}

const LessonItem:React.FC<Props> = ( { key, title, img, lead } ) =>{
  
  return(
    <Item>
      <LessonInner>
        <LessonName>{ title }</LessonName>
        <LessonIcon>
          <img src={require(`../../../Asets/Lesson/${img}`)} alt={ title }/>
        </LessonIcon>
        <Lead>
          { lead }
        </Lead>
      </LessonInner>
      <LessonStartBtn>
        {title === "ビジネス&マーケット" ?
          <Link to="/QuestionBusiness">
            <a href="#">レッスンを始める</a>
          </Link>
        :""
        }
        {title === "フロントエンド" ?
          <Link to="/QuestionFront">
            <a href="#">レッスンを始める</a>
          </Link>
        :""
        }
        {title === "Webディレクター" ?
          <a>準備中</a>
        :""
        }
        {title === "デザイナー" ?
          <a>準備中</a>
        :""
        }
      </LessonStartBtn>
    </Item>
  );
}

const Lead = styled.p`
  color:#2b546a;
  line-height: 1.6em;
  font-size: 1.4rem;
  text-align: left;
  margin-bottom: 20px;
`

const Item = styled.li`
  background-color: #ffffff;
  width: 270px;
  height: 430px;
  margin: 10px;
  text-align: center;
  box-shadow: 0px 0px 8px #eaeaea;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 20px;
`

const LessonInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const LessonName = styled.h3`
  font-size: 2rem;
  margin-bottom: 30px;
  color:#2b546a;
`

const LessonIcon = styled.div`
  width:100%;
  max-width: 110px;
  padding-bottom: 20px;
  margin: 0 auto;
  img{
    width: 100%;
  }
`

const LessonStartBtn = styled.div`
  width: 80%;
  margin: 0 auto;
  border-radius: 100px;
  box-shadow: 0px 8px 5px -4px #eaeaea;
  transition: .2s;
  &:hover{
    box-shadow: 0px 0px 10px -4px #eaeaea;
  }
  a{
    font-size: 1.8rem;
    line-height: 2em;
    color:#2b546a;
  }
`


export default LessonItem;