import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

type Props = {
  funcA1: any,
  funcA2: any
  residue: number,
  category: number,
}

const AnswerModal:React.FC<Props> = ({ funcA1, funcA2, residue, category }) => {


  return(
    <Answer>
      <Answer__overlay>
        <Answer__inner>
          <Exactly>
            ◎<br></br>
            正解
          </Exactly>
          {residue <= category ?
            <SlectBtn>
              <EndBtn><button onClick={funcA2}>やめる</button></EndBtn>
              <NextBtn><button onClick={funcA1}>次へ</button></NextBtn>
            </SlectBtn>
            :
            <SlectBtn>
              <NextBtn><button onClick={funcA2}>結果</button></NextBtn>
            </SlectBtn>
          }
        </Answer__inner>
      </Answer__overlay>
    </Answer>
  )
}

const Answer = styled.div`
  position: fixed;;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
`
const Answer__overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
`
const Answer__inner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 700px;
  height: 300px;
  padding: 30px;
  background-color: #ffffff;
  color: #2b546a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;
  ${media.lessThan("medium")`
    width: 92%;
    padding: 15px;
    height: 190px;
  `}
`

const Exactly = styled.div`
  font-size: 6rem;
  line-height: 1.1em;
  color: #ee4f4f;
  text-align: center;
  margin-bottom: 40px;
  ${media.lessThan("medium")`
    font-size: 4rem;
    margin-bottom: 20px;
  `}
`

const SlectBtn = styled.div`
  display: flex;
  justify-content: space-around;
  width: 60%;
  margin: 0 auto;
  ${media.lessThan("medium")`
    width: 100%;
  `}
`

const NextBtn = styled.div`
  width: 140px;
  background-color: #fb617e;
  text-align: center;
  border-radius: 2px;
  button{
    display: block;
    width: 100%;
    color: #ffffff;
    font-size: 2.3rem;
    line-height: 1.9em;
  }
  ${media.lessThan("medium")`
    width: 120px;
    height: 36px;
    button{
      font-size: 1.8rem;
      line-height: 1.9em;
    }
  `}
`

const EndBtn = styled.div`
  width: 140px;
  background-color: #646464;
  text-align: center;
  border-radius: 2px;
  button{
    display: block;
    width: 100%;
    color: #ffffff;
    font-size: 2.3rem;
    line-height: 1.9em;
  }
  ${media.lessThan("medium")`
    width: 120px;
    height: 36px;
    button{
      font-size: 1.8rem;
      line-height: 1.9em;
    }
  `}
`

export default AnswerModal;