import React, { useState } from 'react';
import Header01 from '../../Components/CommonParts/Header01';
import LessonItem from './parts/LessonItem';
import { LessonItemData } from '../../Data/MenuData';
import styled from 'styled-components';
import media from 'styled-media-query';

const Top:React.FC = () => {


  return(
    <Content>
      <Header01 />
      <div className="Inner">
        <Cotainer>
          <LessonBox>
            { LessonItemData.map((item) => (
              <LessonItem
                key={item.id}
                title={item.title}
                img={item.img}
                lead={item.lead}
              />
            ))}
          </LessonBox>
        </Cotainer>
      </div>
    </Content>
  )
}

const Content = styled.div`
  background-color: #f9fbfe;
  height: 100%;
`

const Cotainer = styled.div`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  ${media.lessThan("medium")`
    padding: 0 20px 20px 20px;
  `}
`
const LessonBox = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export default Top;