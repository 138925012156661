import { QuestionType } from '../../Types/Types'

/*=========================
Lesson List
=========================*/
export const FrontData:QuestionType[] = [
  {
    id:"0",
    title:"リセットCSS",
    ct01:"フロントエンド",
    ct02:"",
    ct03:"",
    level:3,
    exactly:"ブラウザによって異なる「デフォルトのCSS」を打ち消してブラウザ間の表示を揃えるためのCSSのこと",
    mistake01:"CSS内にJavaScriptを記述出来るようにする拡張機能のこと",
    mistake02:"ページの読み込み速度を改善するために、初回の描写に必ず必要なプロパティのみを記述したCSSのこと",
    ex_mean:`ブラウザによって異なるデフォルトのCSSを打ち消してブラウザ間の表示を揃えるためのCSSのことです。<br>
    このCSSを最初に読み込んだ後に自ら記述したCSSを読み込ませて使用します。<br><br>
    <h4 class="ttlMis">リセットCSSの種類</h4>
    リセットCSSは代表的なものがいくつか存在しそれぞれの特徴が異なるので、プロジェクトにあったものを選びましょう。<br><br>
    ・HTML5 Doctor CSS Reset<br>
    ・Normalize.css<br>
    ・ress<br>
    ・sanitize.css<br>
    とりあえず使用するのであれば「HTML5 Doctor CSS Reset」がおすすめです。
    `,
    ex_relation:"	CSSExpression, クリティカルCSS, CSS in JS"
  },
  {
    id:"1",
    title:"font-size:62.5%;",
    ct01:"フロントエンド",
    ct02:"",
    ct03:"",
    level:4,
    exactly:"文字サイズの単位を「1rem = 10px」とするために「html, body要素」に記述するCSSプロパティのこと",
    mistake01:"文字サイズの単位を「1rem = 10px」とするために「div, span要素」に記述するCSSプロパティのこと",
    mistake02:"文字サイズの単位を「1rem = 16px」とするために「html, body要素」に記述するCSSプロパティのこと",
    ex_mean:`文字サイズの単位を「1rem = 10px」とするために「html, body要素」に記述するCSSプロパティのことです。<br><br>
    ブラウザのデフォルトフォントサイズが16pxのため「rem, em, %」のような「相対的」な単位を使用する場合、
    指定する数値がかなり複雑になってしまいます。<br>
    例)23pxを指定したい場合、23 ÷ 16 = 1.4375となり｛font-size: 1.4375em(23px)｝となる<br><br>
    そこで「html, body要素」に{font-size: 62.5%;}と記述することで<br>
    「1rem = 10px」<br>
    「2.3rem = 23px」<br>
    となり、分かりやすい記述となります。<br><br>
    <h4 class="ttlMis">ブラウザの最小単位には注意！</h4>
    ブラウザ毎によって表示できる最小のフォントサイズが異なります。<br>
    Chromeなどは最小単位が「10px」のため、{font-size:62.5%;}を使用した場合
    「1rem」が最少単位となり「0.8rem」などを指定しても表示されるのは「1rem」の値です。
    `,
    ex_relation:"rem, em"
  },
  {
    id:"2",
    title:"BEM",
    ct01:"フロントエンド",
    ct02:"",
    ct03:"",
    level:5,
    exactly:"CSSのクラス名を「Block__Element--Modifier」という3つの概念をもとに命名してくCSS設計のこと",
    mistake01:"「Break Effect Model」という3つの概念をもとにCSSの動的な部分を設計していく手法のこと",
    mistake02:"CSSを「Block」「Element」「Model」という3つのファイルに振り分けて管理する手法のこと",
    ex_mean:`CSSのクラス名を「Block__Element--Modifier(ﾌﾞﾛｯｸ__ｴﾚﾒﾝﾄ--ﾓﾃﾞｨﾌｧｲｱ)」という3つの概念をもとに命名してくCSS設計方法のことです。<br>
    基本的にはSCSS(ｻｽ)と呼ばれるCSSをより効率的に記述するための言語と一緒に使用されます。<br>
    開発現場においては非常によく使用されているCSS設計方法です。<br><br>
    <h4 class="ttlMis">Block__Element--Modifierとは?</h4>
    「Block」とはその名の通り一つのまとまりを表します。(ボタン、ヘッダー、一つの記事など…)<br>
    「Element」とはBlockの中の役割を指します。(ボタンのテキスト、ヘッダーのロゴ、記事のタイトルなど…)<br>
    「Modifire」とは上記の装飾を指します。(ボタンの色、ヘッダロゴの大きさ…)<br><br>
    <h4 class="ttlMis">BEMの記述例 Header編</h4>
    <pre class="codeBox">
＜<span class="tag">header</span> <span class="cla">class</span><span class="cna">="header header--backColorRed"</span>＞
    ＜<span class="tag">div</span> <span class="cla">class</span><span class="cna">="header__leftBox"</span>＞
    　＜<span class="tag">a</span> <span class="cla">class</span><span class="cna">="header__btn header__btn--blue"</span>＞青ボタン＜/<span class="tag">a</span>＞
    　＜<span class="tag">a</span> <span class="cla">class</span><span class="cna">="header__btn header__btn--green"</span>＞緑ボタン＜/<span class="tag">a</span>＞
    ＜/<span class="tag">div</span>＞
    ＜<span class="tag">div</span> <span class="cla">class</span><span class="cna">="header__rightBox"</span>＞
      ＜<span class="tag">ul</span> <span class="cla">class</span><span class="cna">="menuList"</span>＞
      　＜<span class="tag">li</span> <span class="cla">class</span><span class="cna">="menuList__item"</span>＞
      　　＜<span class="tag">a</span> <span class="cla">class</span><span class="cna">="menuList__link"</span> href="#"＞メニュー＜/<span class="tag">a</span>＞
      　＜/<span class="tag">li</span>＞
      ＜/<span class="tag">ul</span>＞
    ＜/<span class="tag">div</span>＞
＜/<span class="tag">header</span>＞</pre><br>
    <h4 class="ttlMis">BEMのルール</h4>
    上記を見ても分かる通りBEMには以下のルールが存在します。<br>
    ・「Element」「Modifire」はそれぞれ単体で使用しない<br>
    ・「Block__Block__Element」のような孫要素を作らない

    `,
    ex_relation:"OOCSS, SMACSS"
  },
  {
    id:"3",
    title:"SCSS",
    ct01:"フロントエンド",
    ct02:"",
    ct03:"",
    level:5,
    exactly:"CSSプリプロセッサの一種で「CSS」を効率的に記述するための言語のこと",
    mistake01:"「静的型付け」と呼ばれ、プログラマが予め変数や関数に型を決めて扱う「JavaScript」のこと",
    mistake02:"既定形式の入力補完機能により「HTML, XML」等を素早く編集できるテキストエディタ用プラグインこと",
    ex_mean:`SCSS(ｼﾝﾀｯｸｷｬﾘｰ・ｵｰｻﾑ・ｽﾀｲﾙ・ｼｰﾄ)、通称サス、とは「CSSプリプロセッサ」と呼ばれる
    CSSをよりプログラミングに近い形で表現し、通常のCSSよりも可読性や保守性を向上させた言語の一種で、
    CSSにはないネスト(入れ子構造)や変数などといった概念があり、より簡潔に分かりやすく記述することができます。<br>
    またその利便性から開発現場ではおよそ必須の知識となっています。<br><br>
    <h4 class="ttlMis">SCSSはコンパイルして使用する</h4>
    SCSSを含むCSSプリプロセッサはあくまでCSSを効率的に記述するための言語なので、実際にスタイルとして適用する際はSCSSファイル
    などをCSSファイルにコンパイル(変換)して使用します。<br>
    コンパイルの方法は色々あるので詳しい方法は割愛しますが「VScode, Dreamweaver」などと言ったテキストエディタの機能を使用したり
    「タスクランナー」という自動処理システムを利用するなどがあげられます。<br><br>
    <h4 class="ttlMis">SCSSとSASS</h4>
    サスには「SCSS」「SASS」と言う2種類の記法があり、基本的にどちらも同じものなのですが、視認性のしやすさから
    「SCSS」が一般的には広く利用されています。
    `,
    ex_relation:"タスクランナー, BEM, コンパイラー"
  },
  {
    id:"4",
    title:"タスクランナー",
    ct01:"フロントエンド",
    ct02:"",
    ct03:"",
    level:4,
    exactly:"開発に必要なあらゆる処理や作業を「自動」で行ってくれるプログラムのこと",
    mistake01:"開発に必要な処理や作業をまとめた「一覧表」のこと",
    mistake02:"既定形式の入力補完機能により「HTML, XML」等を素早く編集できるテキストエディタ用プラグインこと",
    ex_mean:`開発に必要なあらゆる処理や作業を自動で行ってくれるプログラムのことです。<br>
    タスクランナーは種類とその役割共に様々なので、ここでは主要なタスクランナーや
    開発現場で使用される具体的な処理についてのみ触れます。<br>
    また開発現場では必ず何かしらのタスクランナーを使用していますので有名なものは覚えましょう。<br><br>
    <h4 class="ttlMis">タスクランナーで出来ること一部</h4>
    ・SCSSファイルをCSSにコンパイル(変換)する<br>
    ・CSS, JacaScript, 画像の圧縮<br>
    ・CSSのベンダープレフィックスを付ける<br>
    ・ES6で記述されたJavaScriptをES5に変換<br><br>
    <h4 class="ttlMis">有名なタスクランナー一部</h4>
    Gulp(ｶﾞﾙﾌﾟ)<br>
    一番有名、上記に記載されている処理は全てこちらで自動処理化できます<br><br>
    Webpack(ｳｪﾌﾞﾊﾟｯｸ)<br>
    CSS, JavaScript, 画像などコンテンツを構成するあらゆるファイルを「バンドル」という1つのファイルに
    最適な形で変換するツール<br><br>
    Rollup(ﾛｰﾙｱｯﾌﾟ)<br>
    複数ファイルに書かれたJavaScriptを1つのモジュールにしてくれるツールです<br><br>
    `,
    ex_relation:"ビルドツール, node.js"
  },
  {
    id:"5",
    title:"Emmet",
    ct01:"フロントエンド",
    ct02:"",
    ct03:"",
    level:3,
    exactly:"「HTML、CSS」等を素早く記述、編集できるテキストエディタ用の入力補完プラグインこと",
    mistake01:"「TypeScript、JavaScript」等を素早く記述、編集できるテキストエディタ用の入力補完プラグインこと",
    mistake02:"「PHP, Ruby」等を素早く記述、編集できるテキストエディタ用の入力補完プラグインこと",
    ex_mean:`Emmet(ｴﾒｯﾄ)とは「HTML、CSS」等を素早く記述、編集できるテキストエディタ用の入力補完プラグインことです。<br>
    予め決められたショートカットを入力することでHTMLタグやCSSプロパティを素早く記述することが出来るので
    コーダーの殆どが使用しています。<br>またプラグインですので使用する際はテキストエディタからEmmet機能をインストール
    する必要があります。<br><br>
    <h4 class="ttlMis">使用例</h4>
    コーディングの際、以下のように記述後「Tabキー」を入力すると
    <pre class="codeBox">
    ul.list>(li.item>a)*2</pre>
    以下のようになる<br>
    <pre class="codeBox">
    ＜<span class="tag">ul</span> <span class="cla">class</span><span class="cna">="list"</span>＞
        ＜<span class="tag">li</span> <span class="cla">class</span><span class="cna">="item"</span>＞＜<span class="tag">a</span> href=""＞＜/<span class="tag">a</span>＞＜/<span class="tag">li</span>＞
        ＜<span class="tag">li</span> <span class="cla">class</span><span class="cna">="item"</span>＞＜<span class="tag">a</span> href=""＞＜/<span class="tag">a</span>＞＜/<span class="tag">li</span>＞
    ＜/<span class="tag">ul</span>＞</pre>
    `,
    ex_relation:"特になし"
  },
  {
    id:"6",
    title:"ベンダープレフィックス",
    ct01:"フロントエンド",
    ct02:"",
    ct03:"",
    level:3,
    exactly:"「ブラウザ」のCSS拡張機能を実装するのに必要な識別子のこと",
    mistake01:"「端末」毎に異なるCSSプロパティの仕様を揃えるために必要な識別子のこと",
    mistake02:"ブラウザによって異なる「デフォルトのCSS」を打ち消してブラウザ間の表示を揃えるために必要な識別子のこと",
    ex_mean:`ブラウザのCSS拡張機能を実装するのに必要な識別子のことです。<br>
    「Flex」などの比較的新しいCSS機能はブラウザ毎にその機能を呼び出すための識別子が異なるので
    ベンダープレフィックスを使用し、どのブラウザでも同じプロパティを使用できるようにします。<br>
    またブラウザ毎のデバックを行った際に一部ブラウザのみで表示が崩れている場合はまずこのベンダープレフィックスの付け忘れを疑います。<br><br>
    <h4 class="ttlMis">ベンダープレフィックス一覧</h4>
    ブラウザー毎に以下のようなベンダープレフィックスを実装します。<br>
    また当然プロパティ毎にこれらすべての接頭辞を付ける必要はありません。<br>
    <table class="table">
      <tr>
        <th>接頭辞</th><th>対応ブラウザ</th>
      </tr>
      <tr>
        <td>-webkit-</td><td>Google Chrome、Safari</td>
      </tr>
      <tr>
        <td>-moz-</td><td>Firefox</td>
      </tr>
      <tr>
        <td>-ms-</td><td>Microsoft Internet Explorer</td>
      </tr>
      <tr>
        <td>-o-</td><td>Opera</td>
      </tr>
    </table>
    <h4 class="ttlMis">ベンダープレフィックスの使い方</h4>
    CSSで{display:flex;}を使用する場合
    <pre class="codeBox">
    .test{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }</pre>
    `,
    ex_relation:"特になし"
  },
  {
    id:"7",
    title:"アジャイル開発",
    ct01:"フロントエンド",
    ct02:"",
    ct03:"",
    level:2,
    exactly:"システム開発を「機能単位」の小規模で「要件定義・設計・開発・実装・テスト・運用」のサイクルを繰り返して行う開発手法のこと",
    mistake01:"システム開発をプロジェクト単位の大規模で「要件定義・設計・開発・実装・テスト・運用」で行う開発手法のこと",
    mistake02:"設計、開発からテスト、運用までの開発工程を「1日単位」のサイクルで行う開発手法のこと",
    ex_mean:`システム開発を「機能単位」の小規模で「要件定義・設計・開発・実装・テスト・運用」のサイクルを繰り返して行う開発手法のことです。<br>
    アジャイル開発は優先度の高い要件から順に開発を進めていき、開発した各機能を集合体として1つの大きなシステムを形成します。<br>
    またアジャイル開発は「プロジェクトに変化はつきもの」という前提で進められるので仕様変更に強くプロダクトの価値を最大化することに重点を置いた、
    現在、システム開発において最も主流となっている開発手法です。<br><br>
    <h4 class="ttlMis">ウォーターフォール開発</h4>
    アジャイル開発の対となる開発方法でシステムの開発を「基本計画・外部設計・内部設計・プログラム設計・プログラミング・テスト」
    という工程に分けて順に段階を経て行う方法です。<br>
    「前の工程には戻らない」ことが前提であることから下流から上流へは戻らない水の流れにたとえてウォータフォールと呼ばれています。`,
    ex_relation:"特になし"
  },
  {
    id:"8",
    title:"TypeScript",
    ct01:"フロントエンド",
    ct02:"",
    ct03:"",
    level:4,
    exactly:"JavaScriptを拡張して作られた「静的型付け」のクラスベースオブジェクト指向言語のこと",
    mistake01:"JavaScriptを拡張して作られた「動的型付け」のクラスベースオブジェクト指向言語のこと",
    mistake02:"JavaScriptを拡張して作られた「順的型付け」のクラスベースオブジェクト指向言語のこと",
    ex_mean:`TypeScript(ﾀｲﾌﾟｽｸﾘﾌﾟﾄ)とは、Microsoftによって開発されたJavaScriptを拡張して作られた
    「静的型付け」のクラスベースオブジェクト指向言語のことです。<br>
    またTypeScriptはあくまでJavaScriptを「静的型付け」的に記述するための言語なので、実際にプログラムとして適用する際はTypeScriptファイル
    をJavaScriptファイルにコンパイル(変換)して使用します。<br><br>
    <h4 class="ttlMis">JavaScriptとの主な違い</h4>
    <table class="table">
      <tr>
        <th>JavaScript</th><th>TypeScript</th>
      </tr>
      <tr>
        <td>動的型付け</td><td>静的型付け</td>
      </tr>
      <tr>
        <td>クラスが使えない</td><td>クラスを使える</td>
      </tr>
      <tr>
        <td>コンパイルが不要</td><td>コンパイルが必要</td>
      </tr>
    </table>

    <h4 class="ttlMis">静的型付けとは？</h4>
    ある変数や関数が扱うことの出来るデータ型を予め定義しておくことです。<br>
    こうすることでプログラムを実行する前にエラー検出を出来るようになることが最大のメリットです。<br><br>
    ・型つ付けるイメージ
    <pre class="codeBox">
    <span class="com">//データ型を定義</span>
    <span class="tag">Type</span> <span class="cla">UserData</span> = {
      id: <span class="cna">number</span>, <span class="com">//idキーには数字しか入れられない</span>
      name: <span class="cna">string</span>, <span class="com">//nameキーには文字列しか入れられない</span>
      login: <span class="cna">boolean</span> <span class="com">//loginキーには真偽値しか入れられない</span>
    }

    <span class="com">//エラーのないパターン</span>
    <span class="tag">const</span> Test:<span class="cla">UserData</span> = {
      id: <span class="cna">1</span>,
      name: <span class="cna">"井出 啓貴"</span>,
      login: <span class="cna">true</span>,
    }

    <span class="com">//エラーのでるパターン</span>
    <span class="tag">const</span> Test:<span class="cla">UserData</span> = {
      id: <span class="cna">"1"</span>, <span class="com">//文字列の数字をいれてしまっている</span>
      name: <span class="cna">"井出 啓貴"</span>,
      login: <span class="cna">true</span>,
      sex: <span class="cna">"男性"</span>, <span class="com">//データ型にないキーを入れようとしている</span>
    }</pre>
    `,
    ex_relation:"Babel, タスクランナー, 動的型付け"
  },
  {
    id:"9",
    title:"特殊文字コード",
    ct01:"フロントエンド",
    ct02:"",
    ct03:"",
    level:3,
    exactly:"HTML特殊文字や環境依存文字を表示させる専用の文字コードのこと",
    mistake01:"予めサーバ上に用意されているフォントやインターネット上で提供されているフォントを呼び出して表示するたの文字列のこと",
    mistake02:"ページ全体に影響を及ぼす特殊なCSS識別子のこと",
    ex_mean:`特殊文字コードとはHTML特殊文字や環境依存文字を表示させる専用の文字コードのことです。<br>
    「文字コード」とは、コンピューターは数値でしかデータを扱えないため、アルファベットや漢字には、
    それぞれにコンピューターが認識するためのシリアルナンバーが割り当てられています。それが文字コードと呼ばれるものです。<br>
    基本的にアルファベットや漢字などは「UTF-8」といった文字コード規格で補完されているので問題なく表示されるのですが
    、「環境依存文字」という一定の文字はこの特殊文字コードを入力しなければ「文字化け」を起こしてしまうので注意が必要です。<br><br>
    <h4 class="ttlMis">よく使用される特殊文字コード</h4>
    <table class="table">
      <tr>
        <th class="wid-50">表示される文字</th><th>文字コード</th>
      </tr>
      <tr>
        <td>※</td><td>&＃8251;</td>
      </tr>
      <tr>
        <td>&#9312;</td><td>&＃9312;</td>
      </tr>
      <tr>
        <td>&#60;</td><td>&＃60;</td>
      </tr>
    </table><br>
    <h4 class="ttlMis">実際の使用例</h4>
    <pre class="codeBox">＜<span class="tag">p</span>＞<span class="cna">&＃8251;</span>キャンペーン期間は事前に告知なく変更される場合があります。＜/<span class="tag">p</span>＞</pre><br>
    ・ブラウザでの表示<br>
    &#8251;キャンペーン期間は事前に告知なく変更される場合があります。`,
    ex_relation:"UTF-8, Unicode, Sift_JIS"
  },
  {
    id:"10",
    title:"CMS",
    ct01:"フロントエンド",　
    ct02:"ディレクター",
    ct03:"",
    level:3,
    exactly:"Web制作に必要な専門的な知識が無くてもWebサイトやコンテンツを構築・管理・更新できるシステムのこと",
    mistake01:"「テンプレート」と呼ばれるHTMLのひな形を元にプログラムで加工して画面に出力するためのシステムのこと",
    mistake02:"YahooやGoogleなどから広告のデータを取得して自前のプログラムで表示させるシステムのこと",
    ex_mean:`CMS(ｺﾝﾃﾝﾂ・ﾏﾈｼﾞﾒﾝﾄ・ｼｽﾃﾑ)とは、Web制作に必要な専門的な知識が無くてもWebサイトやコンテンツを構築・管理・更新できるシステムのことです<br><br>
    <h4 class="ttlMis">代表的なCSM</h4>
    WordPress(ﾜｰﾄﾞﾌﾟﾚｽ)<br>
    Wix(ｳｨｯｸｽ)<br>
    Concrete5(ｺﾝｸﾘｰﾄﾌｧｲﾌﾞ)
    `,
    ex_relation:"特になし"
  },
  {
    id:"11",
    title:"CDN",
    ct01:"フロントエンド",　
    ct02:"",
    ct03:"",
    level:3,
    exactly:"Webコンテンツをインターネット経由で配信するために最適化されたネットワークのこと",
    mistake01:"Web制作に必要な専門的な知識が無くてもWebサイトやコンテンツを構築・管理・更新できるシステムのこと",
    mistake02:"「テンプレート」と呼ばれるHTMLのひな形を元にプログラムで加工して画面に出力するためのシステムのこと",
    ex_mean:`CDN(ｺﾝﾃﾝﾂ・ﾃﾞﾘﾊﾞﾘ・ﾈｯﾄﾜｰｸ)とは、Webコンテンツをインターネット経由で配信するために最適化されたネットワークのことです。<br>
    「jQuery」「Google Font」などを始め、画像、CSS、JavaScriptなどあらゆるライブラリをこの方式で読み込むことができます。<br>
    またCDNを利用することによってサーバーへの接続数を分散することができ、サーバーが不安定になる可能性が減るのでダウンロード速度が安定するなどの
    メリットもあります。<br>
    <h4 class="ttlMis">jQueryをCDNで読み込む例</h4>
    <pre class="codeBox">
＜<span class="tag">head</span>＞
  ＜<span class="tag">script</span> <span class="cla">src</span><span class="cna">="https://code.jquery.com/jquery-3.3.1.js"</span>＞＜/<span class="tag">script</span>＞<span class="com">//ネットを経由してjQueryを読み込んでいる</span>
＜/<span class="tag">head</span>＞
    </pre>
    `,
    ex_relation:"特になし"
  },
  {
    id:"12",
    title:"Dos攻撃",
    ct01:"フロントエンド",　
    ct02:"バックエンド",
    ct03:"",
    level:4,
    exactly:"サーバやネットワークなどのリソースに意図的に過剰な負荷をかけたり脆弱性をついたりする事でサービスを妨害すること",
    mistake01:"アプリケーションのセキュリティ上の不備を意図的に利用しアプリケーションが想定しないSQL文を実行させること",
    mistake02:"ユーザからの入力内容をWebページに表示するWebアプリケーションにおいて、Webサイト脆弱性を利用した攻撃のこと",
    ex_mean:`Dos(ﾄﾞｽ)攻撃とはサーバやネットワークなどのリソースに意図的に過剰な負荷をかけたり脆弱性をついたりする事でサービスを妨害することです。<br><br>
    <h4 class="ttlMis">F5アタック</h4>
    Dos攻撃の有名かつ古典的な手法で、ブラウザのページ更新キーである「F5」を大勢の人間で連打しまくることにより
    、サーバーに負荷をかけてダウンさせる手法です。<br><br>
    <h4 class="ttlMis">その他有名な攻撃方法</h4>
    <table class="table">
      <tr>
        <th>SQLインジェクション</th>
      </tr>
      <tr>
        <td>アプリケーションのセキュリティ上の不備を意図的に利用し、アプリケーションが想定しないSQL文を実行させることにより、データベースシステムを不正に操作する攻撃方法</td>
      </tr>
    </table>
    <table class="table">
      <tr>
        <th>XSS(ｸﾛｽｻｲﾄｽｸﾘﾌﾟﾃｨﾝｸﾞ)</th>
      </tr>
      <tr>
        <td>フォームなどの入力内容にスクリプト付のリンクを貼る等の罠を仕掛け、被害者となるユーザが誤ってクリックをすると、
        セキュリティ的に問題のある別のWebサイト(クロスサイト)に対し、脆弱性を利用した悪意あるスクリプトが含まれた通信が実行されます。</td>
      </tr>
    </table>`,
    ex_relation:"特になし"
  },
  {
    id:"13",
    title:"IDE",
    ct01:"フロントエンド",　
    ct02:"バックエンド",
    ct03:"",
    level:2,
    exactly:"「統合開発環境」を意味し、プログラミングをする際に必要なソフトウェアを統合して1つの画面で操作できるようにしたソフトウェアやプラットフォームのこと",
    mistake01:"「クラウド開発環境」を意味し、プログラミングをする際に必要なリソース全てクラウド上で統合・管理すること",
    mistake02:"「ローカル開発環境」を意味し、プログラミングをする際に必要なリソース全てローカル環境で統合・管理すること",
    ex_mean:`IDE(ｲﾝﾃｸﾞﾚﾃｯﾄﾞ・ﾃﾞｳﾞｪﾛｯﾌﾟﾒﾝﾄ・ｴﾝｳﾞｧｲﾛﾒﾝﾄ)とは「統合開発環境」を意味し、プログラミングをする際に必要なソフトウェアを統合して1つの画面で操作できるようにしたソフトウェアやプラットフォームのことです。<br>
    開発に必要なソフトウェアを個別に用意しなくて良いので環境構築が簡単というメリットがあります。<br><br>
    <h4 class="ttlMis">有名なIDEサービス</h4>
    「Visual Studio」<br>
    「AWS Cloud9」<br>
    「Xcode」
    `,
    ex_relation:"特になし"
  },
  {
    id:"14",
    title:"Git",
    ct01:"フロントエンド",　
    ct02:"バックエンド",
    ct03:"ディレクター",
    level:5,
    exactly:"ソースコードなどの変更履歴を記録・追跡するための「バージョン管理システム」のこと",
    mistake01:"ソースコードや画像など、あらゆるリソースのファイル名に「日付」を付けてることで変更履歴を管理する手法のこと",
    mistake02:"ソースコードなどの変更履歴の記録をビジュアライズして確認できるソフトウェア群のこと",
    ex_mean:`Git(ｷﾞｯﾄ)とは、ソースコードなどの変更履歴を記録・追跡するための「バージョン管理システム」のことです。<br>
    最大のメリットは変更履歴を記録する際、古いファイルと新しいファイルの差分を検知し、変更部分だけを更新するという点にあります。<br>
    これにより1つのファイルを同時に複数の人間で制作・修正しても
    他の人が更新した部分を、自分の古いファイルで上書きしてしまう…というリスクがなくなります。<br>
    しかしチームでGitを運用する場合は、ある程度の決まったルールの中で行わなければ
    そういった事故が起きてしまうのでしっかりとした注意と知識は必要となります。<br>
    またその利便性から、開発現場ではまず間違いなく利用する技術なので覚えておきましょう。<br><br>
    <h4 class="ttlMis">SourceTreeについて</h4>
    Gitは通常「コマンドプロント」と呼ばれる黒い画面に英語の命令文を記入して操作するのですが、
    慣れない人にはあまりにも視認性・操作性が悪いので、チームでGitを使用する際は
    「SourceTree(ｿｰｽﾂﾘｰ)」というGitをより視覚的に操作できるソフトウェアを利用することが多いです。<br><br>
    <h4 class="ttlMis">Github(ｷﾞｯﾄﾊﾌﾞ)</h4>
    Githubとは、その名の通りGitの利用している世界中の人々が自分のコードやデザインデータなどを保存、公開出来るようにしたWebサービスのことです。<br>
    誤解されやすいですが、Git本体ではないので注意しましょう。<br>
    またGitを覚える上では必ず利用するサービスなので覚えておきましょう。
    `,
    ex_relation:"バージョン管理システム"
  },
  {
    id:"15",
    title:"Ratinaディスプレイ",
    ct01:"フロントエンド",　
    ct02:"デザイン",
    ct03:"",
    level:4,
    exactly:"物理的な表示幅のおよそ2倍のピクセルを表示できる「高精細ディスプレイ」のこと",
    mistake01:"1920 × 1080ピクセルの画面を欠損することなく表示できる「高画質ディスプレイ」のこと",
    mistake02:"縦横比が21:9という通常の2倍以上の領域を表示できる「ワイドディスプレイ」のこと",
    ex_mean:`Ratina(ﾚﾃｨﾅ)ディスプレイとは、物理的な表示幅のおよそ2倍のピクセルを表示できる「高精細ディスプレイ」のことです。<br>
    また「Ratinaディスプレイ」とはApple社の技術名なのですが単純に2倍の解像度を持つスマートフォンやタブレットを指す意味としても使用されます。<br><br>
    <h4 class="ttlMis">Ratina対応の注意点</h4>
    2倍の解像度を誇るディスプレイで画像を表示させる場合は、物理的な画面で表示される2倍のサイズが必要となります。<br>
    例えばiPhone8(幅375px)の中で「100px」領域分に画像を表示させようとした場合、実際には倍に引き延ばされて表示されるので「200px」サイズの画像を用意しなければなりません。<br>
    また当然「100px」の画像を使用した場合、実機で見るとぼやけて表示されてしまいます。`,
    ex_relation:"SVG"
  },
  {
    id:"16",
    title:"ECサイト",
    ct01:"フロントエンド",　
    ct02:"ディレクター",
    ct03:"",
    level:2,
    exactly:"インターネットを利用して製品やサービスを販売するWebサイトのこと",
    mistake01:"企業の公式Webサイトのこと",
    mistake02:"多くの人、もしくは特定のターゲットに対して商品やサービス、企業自体などを宣伝するためのWebサイトのこと",
    ex_mean:`EC(ｴﾚｸﾄﾛﾆｯｸ・ｺﾏｰｽ)サイトとは、インターネットを利用して製品やサービスを販売するWebサイトのことです。<br>
    またECサイトの運営にはCMS(ｺﾝﾃﾝﾂ・ﾏﾈｼﾞﾒﾝﾄ・ｼｽﾃﾑ)を利用することが多いです。<br><br>
    <h4 class="ttlMis">ECサイトの種類</h4>
    モール型と自社型があり、モール型とは「Amazon」のような出店システムを提供しているプラットフォームのことです。<br>
    ショップのひな型や店舗管理システムが用意されているので、出展者はECサイトを自分で構築する必要がなく、決済もモールが代行します。<br>
    しかし売上の一部が利用料となったり、ページの個性が無いのでブランディングイメージが付きにくいなどのデメリットもあります。<br>
    自社型は自社専用のECサイトのことで、メリットはデメリットはモール型の逆と言えます。<br><br>
    <h4 class="ttlMis">代表的なECサイト</h4>
    <table class="table">
      <tr>
        <th class="wid-50">モール型</th>
        <th>自社型</th>
      </tr>
      <tr>
        <td>
          Amazon<br>
          楽天<br>
          ZOZOTOWN
        </td>
        <td>
          BOOKOFF ONLINE<br>
          コナミショップ<br>
          AOKI ONLINE
        </td>
      </tr>
    </table>
    `,
    ex_relation:"コーポレートサイト, LP"
  },
  {
    id:"17",
    title:"コーポレートサイト",
    ct01:"フロントエンド",　
    ct02:"ディレクター",
    ct03:"",
    level:2,
    exactly:"企業の公式Webサイトのこと",
    mistake01:"インターネットを利用して製品やサービスを販売するWebサイトのこと",
    mistake02:"多くの人、もしくは特定のターゲットに対して商品やサービス、企業自体などを宣伝するためのWebサイトのこと",
    ex_mean:`コーポレートサイトとは、企業の公式Webサイトのことです。<br>
    ページの内容は主にBtoB向けであることが多いです。`,
    ex_relation:"ECサイト, LP"
  },
  {
    id:"18",
    title:"UI",
    ct01:"フロントエンド",　
    ct02:"デザイン",
    ct03:"",
    level:4,
    exactly:"Webサイトやアプリなどの「ユーザーの目に触れる部分、使用する部分」全てのこと",
    mistake01:"ユーザーが製品・サービスを通じて得られる「体験」のこと",
    mistake02:"開発に必要なあらゆる処理や作業を「自動」で行ってくれるプログラムのこと",
    ex_mean:`UI(ﾕｰｻﾞｰ・ｲﾝﾀｰﾌｪｲｽ)とはWebサイトやアプリなどの「ユーザーの目に触れる部分、使用する部分」全てのことです。<br>
    パソコンやスマートフォンに表示されるデザイン、フォントなど、製品であれば製品そのものや外観など、ユーザーの視覚に触れる全ての情報が「UI」と呼ばれます。`,
    ex_relation:"UX"
  },
  {
    id:"19",
    title:"UX",
    ct01:"フロントエンド",　
    ct02:"デザイン",
    ct03:"",
    level:4,
    exactly:"ユーザーが製品・サービスを通じて得られる「体験」のこと",
    mistake01:"Webサイトやアプリなどの「ユーザーの目に触れる部分、使用する部分」全てのこと",
    mistake02:"開発に必要なあらゆる処理や作業を「自動」で行ってくれるプログラムのこと",
    ex_mean:`UX(ﾕｰｻﾞｰ・ｴｸｽﾍﾟﾘｴﾝｽ)とはユーザーが製品・サービスを通じて得られる「体験」のことです。<br>
    「体験」の範囲は、製品・サービスを利用する一連の行動の中でユーザーが感じたこと全てがUXという風に捉えて良いでしょう。<br><br>
    <h4 class="ttlMis">WebサイトのUXの例</h4>
    ・デザインがキレイ<br>
    ・フォントが読みやすい<br>
    ・お問い合せフォームや購入ページまでの導線がわかりやすい<br>
    ・商品を注文したらすぐに届いた<br>
    ・対応が丁寧だった<br>
    ・商品のクオリティが高い
    `,
    ex_relation:"UI"
  },
  {
    id:"20",
    title:"グリッドデザイン",
    ct01:"フロントエンド",　
    ct02:"デザイン",
    ct03:"",
    level:3,
    exactly:"画面やページを縦横に分断する直線で格子状に分割し、これを組み合わせてブロック内の要素の大きさや配置を決定していく手法のこと",
    mistake01:"Webサイト・アプリに必要なパーツ・コンポーネント単位でデザイン・制作をしていき、これらを組み合わせてページを制作する手法のこと",
    mistake02:"手前と奥とでスクロールの速度に差をつけることで「視差」を生み出し、奥行きや立体感を表現する手法のこと",
    ex_mean:`グリッドデザインとは画面やページを縦横に分断する直線で格子状に分割し、これを組み合わせてブロック内の要素の大きさや配置を決定していく手法のことです。<br>
    文章や画像、余白など、内部の構成要素の境界線が必ず格子状の線(グリッドライン)に合うように配置することで、
    いろいろな大きさの要素を複雑に配置してもすっきりした見やすい構成にすることができます。<br>
    またレスポンシブに強くグリッドデザイン専用のCSS・JavaScriptライブラリなども存在します。<br><br>
    <h4 class="ttlMis">フロントエンドで対応するデザイン</h4>
    グリッドデザインのようにデザイン手法の中には、開発者が仕組みやその対応を知っておくべきものがあるので有名なものを以下にまとめます。<br>
    <table class="table">
      <tr>
        <th>アトミックデザイン</th>
      </tr>
      <tr>
        <td>Webサイト・アプリに必要なパーツ・コンポーネント単位でデザイン・制作をしていき、これらを組み合わせてページを制作することです。
        近年では最も注目されている制作手法となります。</td>
      </tr>
    </table>
    <table class="table">
      <tr>
        <th>パララックデザイン</th>
      </tr>
      <tr>
        <td>手前と奥とでスクロールの速度に差をつけることで「視差」を生み出し、奥行きや立体感を表現する手法のことです。
        コーポレートサイトやLPなどのブランディングをアピールするページによく利用されます。</td>
      </tr>
    </table>
    `,
    ex_relation:"特になし"
  },
  {
    id:"21",
    title:"SPA",
    ct01:"フロントエンド",　
    ct02:"",
    ct03:"",
    level:5,
    exactly:"「単一のWebページ」でアプリケーションを構成する設計構造のこと",
    mistake01:"「ツリー状のディレクトリ」を持つWebページでアプリケーションを構成する設計構造のこと",
    mistake02:"webページを巡回してサイトの新しいページや更新された箇所を読み、検索結果ページに最新データを反映するロボットのこと",
    ex_mean:`SPA(ｼﾝｸﾞﾙ・ﾍﾟｰｼﾞ・ｱﾌﾟﾘｹｰｼｮﾝ)とは単一のWebページでアプリケーションを構成する設計構造のことです。<br>
    通常のWebページでは遷移時にページ全体が書き換わりますが、SPAではJavaScriptを用いてページ内のHTMLの一部を差し替えてコンテンツを切り替えています。<br>
    これによりブラウザの挙動に縛られないUIの実現やパフォーマンスの向上が可能になります。<br><br>
    <h4 class="ttlMis">有名なライブラリ</h4>
    SPA開発はネイティブのJavaScriptを使用することは殆どありません。<br>
    多くの場合はそれらに特化したライブラリを使用するので有名なものを上げておきます。<br>
    ・React<br>
    ・Vue<br>
    ・Angular
    `,
    ex_relation:"特になし"
  },
  {
    id:"22",
    title:"OGP",
    ct01:"フロントエンド",　
    ct02:"",
    ct03:"",
    level:3,
    exactly:"Facebook、TwitterなどのSNS上でWebページのタイトル、アイキャッチ画像やWebページのURLなどさまざまな情報を伝えるためのプロトコルのこと",
    mistake01:"コンピュータネットワーク上のクライアントとサーバの間でファイル転送を行うための通信プロトコルのこと",
    mistake02:"WebブラウザがWebサーバと通信する際に主として使用する通信プロトコルのこと",
    ex_mean:`OGP(ｵｰﾌﾟﾝ・ｸﾞﾗﾌ・ﾌﾟﾛﾄｺﾙ)とはFacebook、TwitterなどのSNS上でWebページのタイトル、アイキャッチ画像やWebページのURLなどさまざまな情報を伝えるためのプロトコルのことです。<br>
    HTMLのmetaタグに書き込むことで情報を表示することができます。<br><br>
    <h4 class="ttlMis">metaタグの記述例</h4>
    <pre class="codeBox">
    ＜<span class="tag">head</span>＞
      ＜<span class="tag">meta</span> <span class="cla">property=</span><span class="cna">"og:title"</span> <span class="cla">content=</span><span class="cna">"ページのタイトル"</span> /＞
      ＜<span class="tag">meta</span> <span class="cla">property=</span><span class="cna">"og:type"</span> <span class="cla">content=</span><span class="cna">"ページの種類"</span> /＞
      ＜<span class="tag">meta</span> <span class="cla">property=</span><span class="cna">"og:url"</span> <span class="cla">content=</span><span class="cna">"ページのURL"</span> /＞
    ＜/<span class="tag">head</span>＞</pre>
    `,
    ex_relation:"特になし"
  },
  {
    id:"23",
    title:"サブドメイン",
    ct01:"フロントエンド",　
    ct02:"",
    ct03:"",
    level:3,
    exactly:"独自ドメインを目的別や用途別に分割して利用できるドメインのこと",
    mistake01:"独自ドメインの下層ディレクトリをカテゴリー別に分割して分けて利用するドメインのこと",
    mistake02:"国ごと・地域ごとに割り当てられたドメインの総称のこと",
    ex_mean:`サブドメインとは独自ドメインを目的別や用途別に分割して利用できるドメインのことです。<br>
    サブドメインは独自ドメインを取得すると作成することができ、独自ドメインの前に「.」で区切った好きな文字列を挿入して使用します。<br>
    サブドメインは無料または安価で作成することができます。<br><br>
    <h4 class="ttlMis">サブドメインの例 Yahoo!JAPAN</h4>
    <table class="table">
      <tr>
        <th>サービス名</th>
        <th>ドメイン名</th>
      </tr>
      <tr>
        <td>Yahoo!JAPAN</td>
        <td>yahoo.co.jp</td>
      </tr>
      <tr>
        <td>Yahoo!ショッピング</td>
        <td><span style="color:red">shopping</span>.yahoo.co.jp</td>
      </tr>
      <tr>
        <td>Yahoo!天気</td>
        <td><span style="color:red">weather</span>.yahoo.co.jp</td>
      </tr>
      <tr>
        <td>Yahoo!ニュース</td>
        <td><span style="color:red">news</span>.yahoo.co.jp</td>
      </tr>
    </table>
    `,
    ex_relation:"サブディレクトリ"
  },
  {
    id:"24",
    title:"ハイブリットアプリ",
    ct01:"フロントエンド",　
    ct02:"",
    ct03:"",
    level:4,
    exactly:"Webアプリとネイティブアプリの良いところを組み合わせた特長を持ったアプリのこと",
    mistake01:"スマーフォンやタブレットで利用できるアプリのこと",
    mistake02:"Webページを閲覧できる全ての機器で利用できるが、カメラや電話などのデバイス独自の機能は使用できないアプリのこと",
    ex_mean:`ハイブリットアプリとはWebアプリとネイティブアプリの良いところを組み合わせた特長を持ったアプリのことです<br>
    基本的な作り方はWebアプリと同じでHTMLやCSS等のプログラム言語を主に使用しますが、カメラや電話などの端末のネイティブ機能を使用したいときは
    Javascriptを用いたコンポーネントを追加します。これによりネイティブアプリのように振る舞うことが可能になります。<br>
    便利な反面、ネイティブアプリと比べるとパフォーマンスが劣るという欠点もあります。<br><br>
    <h4 class="ttlMis">アプリの種類</h4>
    アプリにはハイブリットアプリを含め3種類あるので覚えておきましょう。それぞれに一長一短があります。<br>
    <table class="table">
      <tr>
        <th>Webアプリ</th>
      </tr>
      <tr>
        <td>Webページを閲覧できる全ての機器で利用できるが、カメラや電話などのデバイス独自の機能は使用できないアプリのこと。<br>
        例)・食べログ　・Wantedly　・Progate
        </td>
      </tr>
    </table>
    <table class="table">
      <tr>
        <th>ネイティブアプリ</th>
      </tr>
      <tr>
        <td>スマーフォンやタブレットのみで利用できるアプリのこと、カメラや電話などのデバイス独自の機能を連携した自由なアプリ制作が可能。<br>
        しかし「iOS」「Android」と別々に制作が必要なため、開発費や時間がかかる。<br>
        例)・ポケモンGo! ・Instagram　・ボイスレコーダー</td>
      </tr>
    </table>
    <table class="table">
      <tr>
        <th>ハイブリットアプリ</th>
      </tr>
      <tr>
        <td>(上記で説明してるので割愛)<br>
        例)・AbemaTV　・Twitter　・クックパッド</td>
      </tr>
    </table>
    `,
    ex_relation:"PWA"
  },
  {
    id:"25",
    title:"WebView",
    ct01:"フロントエンド",　
    ct02:"バックエンド",
    ct03:"",
    level:3,
    exactly:"「ネイティブアプリ内」でWebページを表示するための仕組みのこと",
    mistake01:"「Webページ内」でネイティブアプリを起動するための仕組みのこと",
    mistake02:"Webページを「TVなどのモニター」に表示させるための仕組みのこと",
    ex_mean:`WebView(ｳｪﾌﾞﾋﾞｭｰ)とはネイティブアプリ内でWebページを表示するための仕組みのことです。<br>
    アプリ開発時にWebViewを呼び出すコードを加えるだけで、アプリにWebページを表示する機能が追加できます。<br>
    注意として、ブラウザではないので「Cookie」や「お気に入り」といったブラウザ独自の機能は持ち合わせていません。`,
    ex_relation:"特になし"
  },
  {
    id:"26",
    title:"ファビコン",
    ct01:"フロントエンド",　
    ct02:"デザイン",
    ct03:"",
    level:2,
    exactly:"お気に入り一覧やWebブラウザでページを開いた際にタブ部分に表示されるアイコンのこと",
    mistake01:"ユーザーが最初に訪れたときに目に入る部分のこと",
    mistake02:"Webサイトやアプリなどの「ユーザーの目に触れる部分、使用する部分」全てのこと",
    ex_mean:`ファビコンとはお気に入り一覧やWebブラウザでページを開いた際にタブ部分に表示されるアイコンのことです。<br>
    metaタグにファビコンの画像を指定することで表示させることが出来ます。<br><br>
    <h4 class="ttlMis">マルチアイコン</h4>
    ファビコンの画像サイズは基本的に「16 × 16」「32 × 32」「48 × 48」で、どのサイズの画像を使用しても構いません。<br>
    しかしデフォルトが48px表示の端末に16pxのファビコン画像を用意した場合は当然画像がぼやけてしまいます。<br>
    そこでファビコンは「.icon」という拡張子からなる「マルチアイコン方式」をとるのが一般的です。<br>
    「.icon」は1つの画像データの中に複数のサイズ規格を持てる、という特殊な拡張子なので、上記の3サイズを.iconにまとめて
    端末毎に必要なサイズデータを自動で判別して表示することが出来ます。<br><br>
    <h4 class="ttlMis">metaタグの記述 .iconの場合</h4>
    <pre class="codeBox">
    ＜<span class="tag">head</span>＞
      ＜<span class="tag">link</span> <span class="cla">rel=</span><span class="cna">"icon"</span> <span class="cla">href=</span><span class="cna">"画像のパス.icon"</span> /＞
    ＜/<span class="tag">head</span>＞</pre>
    `,
    ex_relation:"特になし"
  },
  {
    id:"27",
    title:"シークレットモード",
    ct01:"フロントエンド",　
    ct02:"ディレクター",
    ct03:"",
    level:1,
    exactly:"「Google Chrome」における、Webページの閲覧履歴やダウンロード履歴などを保存せずに操作できる機能のこと",
    mistake01:"「Fire Fox」における、Webページの閲覧履歴やダウンロード履歴などを保存せずに操作できる機能のこと",
    mistake02:"「Microsoft Edge」における、Webページの閲覧履歴やダウンロード履歴などを保存せずに操作できる機能のこと",
    ex_mean:`シークレットモードとは「Google Chrome」における、Webページの閲覧履歴やダウンロード履歴などを保存せずに操作できる機能のことです。<br>
    現場では主にプレテストや更新確認を行う際に、キャッシュの影響を受けない状況で確認するために利用されます。<br>
    ショートカットキーは「Shift + Ctrl + N」`,
    ex_relation:"特になし"
  },
  {
    id:"28",
    title:"SSI",
    ct01:"フロントエンド",　
    ct02:"",
    ct03:"",
    level:4,
    exactly:"HTMLの中にWebサーバ側で実行するコマンドを埋め込んでおき、その実行結果をユーザーの表示画面に返す仕組みのこと",
    mistake01:"ネイティブアプリ内でWebページを表示、インクルードするための仕組みのこと",
    mistake02:"ユーザからの入力内容をWebページに表示するWebアプリケーションにおいて、Webサイト脆弱性を利用した攻撃のこと",
    ex_mean:`SSI(ｻｰﾊﾞｰ・ｻｲﾄﾞ・ｲﾝｸﾙｰﾄﾞ)とはHTMLの中にWebサーバ側で実行するコマンドを埋め込んでおき、その実行結果をユーザー側に返す仕組みのことです。<br>
    フロントエンドでは主にHeaderなどの各ページ共通して表示されるセクションに対し「header.html」のようなヘッダーのみのHTMLファイルを1つ用意して、各ページに
    それぞれインクルードして表示させる…といった風に利用されます。<br>
    またSSIを利用する際はWebサーバ自体がSSIに対応またはサービス利用可になっている必要があるので注意です。　
    `,
    ex_relation:"特になし"
  },
  {
    id:"29",
    title:"アイコンフォント",
    ct01:"フロントエンド",　
    ct02:"デザイン",
    ct03:"",
    level:2,
    exactly:"通常、画像として利用するようなアイコンをフォントとして利用できる仕組みのこと",
    mistake01:"フォントを画像化することで、指定フォントが存在しないソフトウェアや端末でも同じフォントを利用するできるようにする仕組みのこと",
    mistake02:"「★」や「◎」などあらゆる文字コードに予め登録してあるアイコンのこと",
    ex_mean:`アイコンフォントとは通常、画像として利用するようなアイコンをフォントとして利用できる仕組みのことです。<br>
    フォントが持つ機能や恩恵をそのまま利用できるのが最大のメリットです。<br><br>
    <h4 class="ttlMis">アイコンフォントの利点</h4>
    ・表示速度が画像よりも早く、ページの読み込みを軽減出来る<br>
    ・CSSなどで色やサイズを簡単に変更できる<br>
    ・サイズ変更による劣化がない<br>
    ・コードとしての入力になるのでユーザーの利用する端末に合わせて表示可能`,
    ex_relation:"特になし"
  },
  {
    id:"30",
    title:"React",
    ct01:"フロントエンド",　
    ct02:"",
    ct03:"",
    level:4,
    exactly:"「Facebook」とコミュニティによって開発されているUI構築のためのフロントエンドWebアプリケーションフレームワークのこと",
    mistake01:"「Google」とコミュニティによって開発されているTypeScriptベースのフロントエンドWebアプリケーションフレームワークのこと",
    mistake02:"「Evan You(ｴｳﾞｧﾝ・ﾖｰ)」という人物が開発した、UI構築に特化したロントエンドWebアプリケーションフレームワークのこと",
    ex_mean:`React(ﾘｱｸﾄ)とは「Facebook」とコミュニティによって開発されているUI構築のためのフロントエンドWebアプリケーションフレームワークのことです。<br>
    「Vue(ﾋﾞｭー)」「Angular2(ｱﾝｷﾞｭﾗｰ)」と並び、アプリ開発の御三家フレームワークです。<br>
    上記の三つはそれぞれに異なった技術や概念が存在しますが「効率的なUI構築」「アプリ開発」など、それらから生み出される目標物は基本的に変わりません。<br>
    なので企業、プロジェクトによって採用しているフレームワークはまちまちであるものの、上記3つのうちどれかはフロントエンドの現場では必須となります。`,
    ex_relation:"SPA, JSX, コンポーネント指向"
  },
  {
    id:"31",
    title:"Vue",
    ct01:"フロントエンド",　
    ct02:"",
    ct03:"",
    level:4,
    exactly:"「Evan You(ｴｳﾞｧﾝ・ﾖｰ)」という人物が開発した、UI構築に特化したロントエンドWebアプリケーションフレームワークのこと",
    mistake01:"「Google」とコミュニティによって開発されているTypeScriptベースのフロントエンドWebアプリケーションフレームワークのこと",
    mistake02:"「Facebook」とコミュニティによって開発されているUI構築のためのフロントエンドWebアプリケーションフレームワークのこと",
    ex_mean:`Vue(ﾋﾞｭー)とは「Evan You(ｴｳﾞｧﾝ・ﾖｰ)」という人物が開発した、UI構築に特化したロントエンドWebアプリケーションフレームワークのことです。<br>
    「React(ﾘｱｸﾄ)」「Angular2(ｱﾝｷﾞｭﾗｰ)」と並び、アプリ開発の御三家フレームワークです。<br>
    上記の三つはそれぞれに異なった技術や概念が存在しますが「効率的なUI構築」「アプリ開発」など、それらから生み出される目標物は基本的に変わりません。<br>
    なので企業、プロジェクトによって採用しているフレームワークはまちまちであるものの、上記3つのうちどれかはフロントエンドの現場では必須となります。<br>
    またVueは上記3つの中では一番学習コストが低いことで知られています。`,
    ex_relation:"SPA, データバインディング, コンポーネント指向"
  },
  {
    id:"32",
    title:"Angular2",
    ct01:"フロントエンド",　
    ct02:"",
    ct03:"",
    level:3,
    exactly:"「Google」とコミュニティによって開発されているTypeScriptベースのフロントエンドWebアプリケーションフレームワークのこと",
    mistake01:"「Evan You(ｴｳﾞｧﾝ・ﾖｰ)」という人物が開発した、UI構築に特化したロントエンドWebアプリケーションフレームワークのこと",
    mistake02:"「Facebook」とコミュニティによって開発されているUI構築のためのフロントエンドWebアプリケーションフレームワークのこと",
    ex_mean:`Angular2(ｱﾝｷﾞｭﾗｰ)とは「Google」とコミュニティによって開発されているTypeScriptベースのフロントエンドWebアプリケーションフレームワークのことです。<br>
    「React(ﾘｱｸﾄ)」「Vue(ﾋﾞｭー)」と並び、アプリ開発の御三家フレームワークです。<br>
    上記の三つはそれぞれに異なった技術や概念が存在しますが「効率的なUI構築」「アプリ開発」など、それらから生み出される目標物は基本的に変わりません。<br>
    なので企業、プロジェクトによって採用しているフレームワークはまちまちであるものの、上記3つのうちどれかはフロントエンドの現場では必須となります。<br>
    Angularは1、2があるのですが基本的にAngularといえば2の方を指します。1は別物なので注意。`,
    ex_relation:"SPA"
  },
  {
    id:"33",
    title:"Common",
    ct01:"フロントエンド",　
    ct02:"",
    ct03:"",
    level:1,
    exactly:"「共通」という意味で、ページ全体で使用されているフォルダやデータのこと",
    mistake01:"「依存」という意味で、相互関係にあるファイルやデータのこと",
    mistake02:"「不触」という意味で、構成や内容を書き換えてはいけないファイルやデータのこと",
    ex_mean:`Common(ｺﾓﾝ)とは「共通」という意味で、ページ全体で使用されているフォルダやデータのことです。<br>
    ページ全体に影響を及ぼす「common.css」や、ページを横断して使用されている画像をまとめた「common_img」フォルダなど
    といった風に利用します。`,
    ex_relation:"特になし"
  },
  {
    id:"34",
    title:"Qiita",
    ct01:"フロントエンド",　
    ct02:"",
    ct03:"",
    level:1,
    exactly:"プログラマのための技術情報共有サービスを提供しているWebサイトのこと",
    mistake01:"プログラマのための、3分動画学習サービスを提供しているサイトのこと",
    mistake02:"「ディレクター」のための技術情報共有サービスを提供しているWebサイトのこと",
    ex_mean:`Qiita(ｷｰﾀ)とはプログラマのための技術情報共有サービスを提供しているWebサイトのことです。<br>
    とても優良な情報が載っているので、エンジニアであれば是非とも登録しましょう。<br>
    また現場でリファレンス共有としてQiitaの記事が使用されていることもあります。`,
    ex_relation:"特になし"
  },
  {
    id:"35",
    title:"HTMLメタ要素",
    ct01:"フロントエンド",　
    ct02:"",
    ct03:"",
    level:34,
    exactly:"ページのタイトルやリンクしているデータなどの「ページそのものに関する」情報を記述する要素のこと",
    mistake01:"目的別にカテゴライズされたセクショニング要素のこと",
    mistake02:"閉じタグが不要で、タグそのものが別の要素に置き換わって表示される要素のこと",
    ex_mean:`HTMLメタ要素とはページのタイトルやリンクしているデータなどの「ページそのものに関する」情報を記述する要素のことです。<br>
    ＜head＞タグの中に記述されタイトル以外は基本的にブラウザには表示されない部分となります。<br><br>
    <h4 class="ttlMis">最低限必要なmeta要素の記述</h4>
    <pre class="codeBox">
    ＜<span class="tag">head</span>＞
      ＜<span class="tag">meta</span> <span class="cla">charset=</span><span class="cna">"utf-8"</span>＞　<span class="com">//文字コードをセットしている</span>
      ＜<span class="tag">meta</span> <span class="cla">http-equiv=</span><span class="cna">"X-UA-Compatible"</span> <span class="cla">content=</span><span class="cna">"IE=edge"</span>＞　<span class="com">//Internet Explorerを使用している人が最新バージョンでサイトを表示するようにする</span>
      ＜<span class="tag">meta</span> <span class="cla">name=</span><span class="cna">"viewport"</span> <span class="cla">content=</span><span class="cna">"width=device-width, initial-scale=1.0"</span>＞　<span class="com">//メディアクエリを利用するためにスケール設定をしている</span>
      ＜<span class="tag">meta</span> <span class="cla">name=</span><span class="cna">"format-detection"</span> <span class="cla">content=</span><span class="cna">"telephone=no"</span>＞　<span class="com">//11桁の数字を電話番号と認識しないようにする</span>
      ＜<span class="tag">meta</span> <span class="cla">name=</span><span class="cna">"description"</span> <span class="cla">content=</span><span class="cna">""</span>＞　<span class="com">//ページの説明文をいれる</span>
      ＜<span class="tag">link</span> <span class="cla">rel=</span><span class="cna">"stylesheet"</span> <span class="cla">href=</span><span class="cna">"css/style.css"</span>>　<span class="com">//CSSを読み込んでいる</span>
      ＜<span class="tag">title</span>＞サイトのタイトル＜/<span class="tag">title</span>＞　<span class="com">//ページのタイトルになる</span>
    ＜/<span class="tag">head</span>＞</pre>`,
    ex_relation:"特になし"
  },
  {
    id:"36",
    title:"UA",
    ct01:"フロントエンド",　
    ct02:"分析",
    ct03:"",
    level:3,
    exactly:"ユーザーが利用しているOSやブラウザ、スマートフォンの機種などの情報のこと",
    mistake01:"Webサイトやアプリなどの「ユーザーの目に触れる部分、使用する部分」全てのこと",
    mistake02:"ユーザーが製品・サービスを通じて得られる「体験」のこと",
    ex_mean:`UA(ﾕｰｻﾞｰ・ｴｰｼﾞｪﾝﾄ)とはユーザーが利用しているOSやブラウザ、スマートフォンの機種などの情報のことです。<br>
    UA情報を取得することで、Webページやアプリをそのユーザーに合わせた情報を送信することができます。<br><br>
    <h4 class="ttlMis">UAの活用例</h4>
    ・パソコン、スマーフォンなどの端末を判別して読み込むCSSを切り替える<br>
    ・iOS、Androidを判別してそれぞれに合わせたスクリプトを読み込んで処理を行う<br>
    ・サービスを利用しているユーザーが使用している、端末の割合を出力して今後のブラッシュアップに役立てる`,
    ex_relation:"特になし"
  },
  {
    id:"37",
    title:"GA",
    ct01:"フロントエンド",　
    ct02:"ディレクター",
    ct03:"分析",
    level:4,
    exactly:"Googleが無料で提供するWebページの総合アクセス解析サービスのこと",
    mistake01:"ユーザーがページ内のどこに注目しているのかをマウスの動きを追跡してサーモグラフィーのように表示するアクセス解析ツールのこと",
    mistake02:"自社ページのGoogle掲載順位、検索キーワードなどを監視、最適化するため解析ツールのこと",
    ex_mean:`GA(ｸﾞｰｸﾞﾙ・ｱﾅﾘﾃｨｸｽ)とはGoogleが無料で提供するWebページの総合アクセス解析サービスのことです。<br>
    アクセス解析とはWebサイトへのアクセスに対して様々な視点から分析を図り、ユーザーの反応やサイトの状況を把握することです。<br>
    またそれらの情報を今後のブラッシュアップのために役立ててサービスやページの品質の向上を図ります。<br><br>
    <h4 class="ttlMis">アクセス解析で取得できる情報</h4>
    <table class="table">
      <tr>
        <th class="wid-30">カテゴリ</th>
        <th>情報</th>
      </tr>
      <tr>
        <td>流入元</td>
        <td>ユーザーがどこから来たか?</td>
      </tr>
      <tr>
        <td>流入キーワード</td>
        <td>どんな目的で来たか?</td>
      </tr>
      <tr>
        <td>ユーザーの属性</td>
        <td>ユーザーの性別、年齢、地域など…etc</td>
      </tr>
      <tr>
        <td>閲覧ページ</td>
        <td>どのページを見たか?</td>
      </tr>
      <tr>
        <td>CV(ｺﾝﾊﾞｰｼﾞｮﾝ)データ</td>
        <td>サイトのゴールまで辿り着いたか?</td>
      </tr>
      <tr>
        <td>デバイス、OS</td>
        <td>パソコン、スマーフォンどちらの端末からアクセスされているか?<br>
        どのようなOSなのか?</td>
      </tr>
    </table>
    `,
    ex_relation:"CV"
  },

  /* {
    id:"",
    title:"",
    ct01:"",　
    ct02:"",
    ct03:"",
    level:3,
    exactly:"",
    mistake01:"",
    mistake02:"",
    ex_mean:``,
    ex_relation:""
  }, */

];

