import React from 'react';
import LogoImg from './CommonParts/Logo';
import StartButton from './CommonParts/StartBtn';
import topImage from '../Asets/Top/topback.jpg';
import pt1 from '../Asets/common/pt1.jpg';
import styled from 'styled-components';
import media from 'styled-media-query';
import { BrowserRouter, Route, Link } from 'react-router-dom';

const Top:React.FC = () => {
  return(
    <BackImage>
      <Header>
        <Logo>
          <LogoImg />
        </Logo>
      </Header>
      <Innner>
        <Intro>
          <p className="Intro__text">
            学べば学ぶほど、自分がどれだけ<br></br>
            無知であるか思い知らされる。<br></br>
            自分の無知に気づけば気づくほど<br></br>
            より一層学びたくなる。
          </p>
          <p className="Intro__Name">- Albert Einstein アルベルト・アインシュタイン -</p>
        </Intro>
        <Lead>
          <p>ビジネス、ITを中心とした専門用語を学習するためのアプリ<br></br>
          あなたの未来に、必要な創造の細胞を</p>
        </Lead>
        <Link to="/Menu">
          <StartButton />
        </Link>
      </Innner>
    </BackImage>
  )
}

const BackImage = styled.div`
  background-image: url('${topImage}');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
`

const Header = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height:300px;
  background-image: url('${pt1}');
  margin-bottom: 40px;
  ${media.lessThan("medium")`
    height:125px;
    margin-bottom: 30px;
    background-size: 5px;
  `}
`

const Logo = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  img{
    width:100%;
  }
  ${media.lessThan("medium")`
    width: 60%;
  `}
`
const Innner = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 10px;
`
const Intro = styled.div`
  color: #ffffff;
  text-align: center;
  margin-bottom: 50px;
  .Intro__text{
    font-size: 2.4rem;
    line-height: 1.8em;
    margin: 0 auto;
  }
  .Intro__Name{
    font-size: 2rem;
    margin-top: 15px;
  }
  ${media.lessThan("medium")`
    .Intro__text{
      font-size: 1.6rem;
      line-height: 1.5em;
    }
    .Intro__Name{
      font-size: 1.4rem;
      margin-top: 15px;
    }
  `}
`

const Lead = styled.div`
  color: #ffffff;
  text-align: center;
  margin-bottom: 40px;
  p{
    font-size: 2.7rem;
    line-height: 1.5em;
  }
  ${media.lessThan("medium")`
    p{
      font-size: 1.6rem;
    }
  `}
`



export default Top;