import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { BrowserRouter, Switch, Route, Link, useLocation } from 'react-router-dom';

type Props = {
  title?: string,
  residue: number,
  text?: any,
  relation?: string,
  func1: any,
  func2: any,
}

const MistakeModal:React.FC<Props> = ( { title, text, relation, func1, func2, residue } ) => {

  console.log(residue);
  return(
    <BrowserRouter>
    <Mistake>
      <Mistake__overlay>
        <Mistake__inner>
          <Exactly>不正解</Exactly>
          <Explanation>
            <Explanation__title>{ title }</Explanation__title>
            <Explanation__box>
              <Explanation__container>
                <H3head>主な意味</H3head>
                <Explanation__text dangerouslySetInnerHTML={{ __html: text }}></Explanation__text>
              </Explanation__container>
              <Explanation__container>
                <H3head>関連することば</H3head>
                <Explanation__text>{ relation }</Explanation__text>
              </Explanation__container>
            </Explanation__box>
          </Explanation>
          {residue <= 45 ?
            <SlectBtn>
              <EndBtn><button onClick={func2}>やめる</button></EndBtn>
              <NextBtn><button onClick={func1}>次へ</button></NextBtn>
            </SlectBtn>
            :
            <SlectBtn>
              <NextBtn><button onClick={func2}>結果</button></NextBtn>
            </SlectBtn>
          }
        </Mistake__inner>
      </Mistake__overlay>
    </Mistake>
    </BrowserRouter>
  )
}

const Explanation__container = styled.div`
  margin-bottom: 35px;
`
const H3head = styled.h3`
  font-size: 1.6rem;
  border-left: 3px solid #f73c8b;
  padding-left: 9px;
  line-height: 1.7em;
  margin-bottom: 13px;
  font-weight: bold;
  ${media.lessThan("medium")`
    font-size: 1.4rem;
    margin-bottom: 7px;
  `}
`
const Explanation__box = styled.div`
  height: auto;
  max-height: 270px;
  overflow-y: scroll;
  line-height: 2em;
  padding: 0px 10px;
  margin-bottom: 20px;
  &::-webkit-scrollbar{
    width: 10px;
  }
  &::-webkit-scrollbar-track{
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb{
    background-color: #ccc;
    border-radius: 10px;
    box-shadow: inset 0 0 0 2px #fff;
  }
  ${media.lessThan("medium")`
    max-height: 300px;
    padding: 0px 5px;
  `}
`
const Mistake = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
`
const Mistake__overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
`
const Mistake__inner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 800px;
  max-height: 530px;
  padding: 25px 40px;
  background-color: #ffffff;
  color: #2b546a;
  border-radius: 3px;
  ${media.lessThan("medium")`
    max-height: 490px;
    width: 92%;
    padding: 10px 15px;
  `}
`
const Exactly = styled.div`
  font-size: 2.7rem;
  line-height: 1.1em;
  color: #3587ce;
  text-align: center;
  margin-bottom: 5px;
  ${media.lessThan("medium")`
    font-size: 2rem;
  `}
`
const Explanation = styled.div`

`
const Explanation__title = styled.p`
  color: #2b546a;
  font-size: 3.3rem;
  text-align: center;
  line-height: 1.4em;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 20px;
  ${media.lessThan("medium")`
    font-size: 2rem;
    line-height: 1.8em;
  `}
`
const Explanation__text = styled.p`
  color: #2b546a;
  font-size: 1.6rem;
  line-height: 1.6em;
  margin-left: 11px;
  ${media.lessThan("medium")`
    font-size: 1.4rem;
  `}
`
const SlectBtn = styled.div`
  display: flex;
  justify-content: space-around;
  width: 60%;
  margin: 0 auto;
  ${media.lessThan("medium")`
    width: 100%;
  `}
`
const NextBtn = styled.div`
  width: 140px;
  background-color: #fb617e;
  text-align: center;
  border-radius: 2px;
  button{
    display: block;
    width: 100%;
    color: #ffffff;
    font-size: 2.3rem;
    line-height: 1.9em;
  }
  ${media.lessThan("medium")`
    width: 120px;
    height: 36px;
    button{
      font-size: 1.8rem;
      line-height: 1.9em;
    }
  `}
`
const EndBtn = styled.div`
  width: 140px;
  background-color: #646464;
  text-align: center;
  border-radius: 2px;
  button{
    display: block;
    width: 100%;
    color: #ffffff;
    font-size: 2.3rem;
    line-height: 1.9em;
  }
  ${media.lessThan("medium")`
    width: 120px;
    height: 36px;
    button{
      font-size: 1.8rem;
      line-height: 1.9em;
    }
  `}
`

export default MistakeModal;