import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';


const StartButton:React.FC = () =>{
  return(
    <StartBtn><a>はじめる</a></StartBtn>
  )
}

const StartBtn = styled.div`
  background: #1dd5e6;
  background: -moz-linear-gradient(top,  #1dd5e6 0%, #46aef7 100%);
  background: -webkit-linear-gradient(top,  #1dd5e6 0%,#46aef7 100%);
  background: linear-gradient(to bottom,  #1dd5e6 0%,#46aef7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1dd5e6', endColorstr='#46aef7',GradientType=0 );
  text-align: center;
  border-radius:100px;
  width: 360px;
  margin: 0 auto;
  transition: .3s;
  a{
    display: block;
    color: #ffffff;
    font-size: 3rem;
    line-height: 2em;
    width:100%;
  }
  &:hover{
    opacity: 0.7;
  }
  ${media.lessThan("medium")`
    width: 250px;
    a{
      font-size: 1.8rem;
      line-height: 2.3em;
    }
  `}
`

export default StartButton;