import { LessonItemType } from '../Types/Types'

/*=========================
Lesson List
=========================*/
export const LessonItemData: LessonItemType[] = [
  {
    id: 0,
    title: "ビジネス&マーケット",
    img: "business.png",
    lead: `ビジネスシーンやマーケティングの専門用語は業種問わず使用されるものが多いです。基本を抑えて今後の業務に役立てましょう`,
  },
  {
    id: 1,
    title: "Webディレクター",
    img: "director.png",
    lead: "ディレクターは多くの職種の方と関わりを持つ仕事です。ITに関する専門用語を広く覚えることで、あらゆる業種の人達と円滑なコミュニケーションをとれるようになりましょう"
  },
  {
    id: 2,
    title: "フロントエンド",
    img: "html.png",
    lead: "プログラミングの世界には膨大な、概念・技術が存在しますが、その中でも基本となる専門用語を覚えおくことで現場での信頼関係を築きやすくしましょう",
  },
  {
    id: 3,
    title: "デザイナー",
    img: "design.png",
    lead: "デザインには確立された手法や概念があり、機械化進んだ現代におけるデザイン力とは、それら多くの引き出しを持つことです。現場で広い視野持ったデザインを行えるようになりましょう",
  },

]