import React from 'react';
import Top from './Components/Top';
import QuestionBusiness from './Components/Question/QuestionBusiness';
import QuestionFront from './Components/Question/QuestionFront';
import Menu from './Components/Menu/Menu';
import { HashRouter, Switch, Route, Link } from 'react-router-dom';

const App:React.FC = () => {
  return (
    <div className="App">
      <HashRouter>
        <Switch>
          <Route exact path='/' component={ Top } />
          <Route path='/Menu' component={ Menu } />
          <Route path='/QuestionBusiness' component={ QuestionBusiness } />
          <Route path='/QuestionFront' component={ QuestionFront } />
        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
