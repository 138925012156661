import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

type Props = {
  title: string
}

const QuestionTitle:React.FC<Props> = ( { title }) =>{
  return(
    <Question__Title> { title } </Question__Title>
  );
}

const Question__Title = styled.p`
  font-size: 2.6rem;
  text-align: center;
  padding-bottom: 15px;
  margin-bottom: 25px;
  border-bottom: 1px solid #eeeeee;
  color: #2b546a;
  ${media.lessThan("medium")`
    font-size: 2rem;
  `}
`

export default QuestionTitle;